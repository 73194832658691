const route = [
  {
    name: "Accueil",
    route: "/home",
  },
  {
    name: "Propriétés",
    route: "/properties",
  },
  {
    name: "Agents",
    route: "/agents",
  },
  {
    name: "Utilisateur",
    menuItem: [
      {
        name: "Mes propriétés",
        route: "/profil/properties",
      },
      {
        name: "Soumèttre une propriété",
        route: "/properties/create",
      },
      {
        name: "profil",
        route: "/profil",
      },
    ],
  },
  {
    name: "Service d'achat",
    route: "/maket",
  },
  {
    name: "Contact",
    route: "/contact",
  },
];

export default route;
