import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

// pages component fiypro
import Home from "view/pages/Home";
import Properties from "view/pages/Properties";
import Property from "view/pages/Property";
import PropertyCreate from "view/pages/PropertyCreate";
import PropertyEdit from "view/pages/PropertyEdit";
import ProfilProperties from "view/pages/ProfilProperties";
import Login from "view/pages/Login";
import Registration from "view/pages/Registration";
import Profil from "view/pages/Profil";
import ContactUs from "view/pages/ContactUs";
import Agents from "view/pages/Agents";

function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="*" element={<Navigate replace to="/home" />} />
      <Route path="/">
        <Route path="home" element={<Home />} />
        <Route path="properties" element={<Properties />} />
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="properties/:slug-:id" element={<Property />} />
        <Route path="profil/:slug-:id" element={<Profil />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="agents" element={<Agents />} />
        <Route
          path="profil"
          element={
            <RequireAuth loginPath="/login">
              <Profil />
            </RequireAuth>
          }
        />
        <Route
          path="properties/create"
          element={
            <RequireAuth loginPath="/login">
              <PropertyCreate />
            </RequireAuth>
          }
        />
        <Route
          path="properties/edit/:id"
          element={
            <RequireAuth loginPath="/login">
              <PropertyEdit />
            </RequireAuth>
          }
        />
        <Route
          path="profil/properties"
          element={
            <RequireAuth loginPath="/login">
              <ProfilProperties />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
}

export default AllRoutes;
