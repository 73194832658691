import React from "react";
import { Box } from "@mui/material";
import error from "assets/images/error.png";

function Error() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{ width: { sm: "80%", md: "60%", xs: "100%" } }}
        component="img"
        src={error}
        alt="error-img"
      />
    </Box>
  );
}

export default Error;
