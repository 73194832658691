import React from "react";
import { Typography } from "@mui/material";

import Search from "view/layouts/Search";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      zIndex: "10000000 !important",
      top: "50% !important",
      transform: "translateY(-50%)",
    },
  })
);

function ModalSearch() {
  const classes = useStyles();
  return (
    <div
      className={[classes.modal, "fade modal"].join(" ")}
      id="PropertiesSearchModal"
      tabIndex="-1"
      aria-labelledby="PropertiesSearchModal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <Typography className="modal-title" id="PropertiesSearchModal" mb={1} variant="h3">
              Recherche
            </Typography>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="modal-filter">
              <Search />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSearch;
