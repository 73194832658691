/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { Box } from "@mui/material";

import image from "assets/images/bg (Grande).jpg";

import PropertyModel from "components/PropertyModel";

import HeaderLayout from "view/layouts/HeaderLayout";

function PropertyCreate() {
  const defaultValues = {
    city: "/fipro_api/public/api/cities/1",
    type: "/fipro_api/public/api/types/1",
    typeSale: "/fipro_api/public/api/type_sales/2",
    floor: 0,
    address: "",
    nameBuild: "",
    marketable: false,
    numberKitchen: 0,
    numberBedroom: 1,
    numberShower: 1,
    surface: 0,
    description: "",
    options: [],
    defaultImage: null,
  };

  const img = useMemo(() => [{ img: image, alt: image }], [image]);

  const method = {
    method: "post",
    id: undefined,
  };

  return (
    <Box>
      <HeaderLayout title="Créer une propriété" img={img} />
      <PropertyModel defaultValues={defaultValues} method={method} />
    </Box>
  );
}

export default PropertyCreate;
