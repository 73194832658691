/* eslint-disable react/prop-types */
// React components
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

// Onacc Admin Dashboard React components

function LabelInput({ children, id }) {
  return (
    <Box mb={1} ml={0.5}>
      <Typography htmlFor={id} component="label" variant="body1" fontWeight="normal">
        {children}
      </Typography>
    </Box>
  );
}

LabelInput.defaultProps = {
  id: "",
};

LabelInput.prototype = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

export default LabelInput;
