import colors from "assets/theme/base/colors";
import playfair from "assets/fonts/PlayfairDisplay-VariableFont_wght.ttf";

const { info, dark, white } = colors;
const globals = {
  "@font-face": {
    fontFamily: "playfair",
    fontStyle: "normal",
    fontDisplay: "swap",
    src: `url(${playfair}) format('ttf')`,
  },
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  body: {
    background: white.main,
  },
};

export default globals;
