import React from "react";
import { Box } from "@mui/material";

import notFound from "assets/images/no-result-found.jpg";

function NoFound() {
  return (
    <Box width={1} display="flex" justifyContent="center" alignItems="center">
      <Box
        sx={{ width: { sm: "80%", md: "60%", xs: "100%" } }}
        component="img"
        src={notFound}
        alt="not found result image"
      />
    </Box>
  );
}

export default NoFound;
