import { createContext, useContext, useReducer } from "react";
import { PropTypes } from "prop-types";

const FilterProperty = createContext();

// Setting custom name for the context which is visible on react dev tools
FilterProperty.displayName = "FIYPROApp";

// Initial value for reducer
function init(initalValue) {
  return initalValue;
}

// Filter React reducer
function reducer(state, action) {
  switch (action.type) {
    case "City":
      return { ...state, cityFilter: action.value };
    case "Type":
      return { ...state, typeFilter: action.value };
    case "Option":
      return { ...state, optionFilter: action.value };
    case "Price":
      return { ...state, priceFilter: action.value };
    case "UrlParams":
      return { ...state, urlParams: action.value };
    case "Reset":
      return init({
        cityFilter: { name: [], data: [] },
        typeFilter: { name: [], data: [] },
        optionFilter: { name: [], data: [] },
        priceFilter: ["", ""],
        urlParams: "",
      });
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// Filter context provider
function FilterPropertyProvider({ children }) {
  let initialState;
  if (localStorage.getItem("filterProperty")) {
    initialState = JSON.parse(localStorage.getItem("filterProperty"));
  } else {
    initialState = {
      cityFilter: { name: [], data: [] },
      typeFilter: { name: [], data: [] },
      optionFilter: { name: [], data: [] },
      priceFilter: ["", ""],
      urlParams: "",
    };
  }

  const [filterProperty, dispatch] = useReducer(reducer, initialState, init);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FilterProperty.Provider value={[filterProperty, dispatch]}>{children}</FilterProperty.Provider>
  );
}

// Filter property custom hook for using context
function useFilterProperty() {
  const context = useContext(FilterProperty);

  if (!context) {
    throw new Error("useFilterProperty should be used inside the FilterPropertyProvider");
  }

  return context;
}

// Typechecking props for the FilterPropertyProvider
FilterPropertyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module function
const setCityFilterProperty = (dispatch, value) => dispatch({ type: "City", value });
const setTypeFilterProperty = (dispatch, value) => dispatch({ type: "Type", value });
const setOptionFilterProperty = (dispatch, value) => dispatch({ type: "Option", value });
const setPriceFilterProperty = (dispatch, value) => dispatch({ type: "Price", value });
const setUrlParamsFilterProperty = (dispatch, value) => dispatch({ type: "UrlParams", value });
const resetFilterProperty = (dispatch) => dispatch({ type: "Reset" });
export {
  FilterPropertyProvider,
  useFilterProperty,
  setCityFilterProperty,
  setTypeFilterProperty,
  setOptionFilterProperty,
  setPriceFilterProperty,
  setUrlParamsFilterProperty,
  resetFilterProperty,
};
