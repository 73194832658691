import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createStyles, makeStyles } from "@mui/styles";
import { PropTypes } from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ImageUploader from "react-images-upload";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import { toast } from "react-toastify";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

// compenents fiypro
import { loadAxios, postWithData } from "components/hooks/hookAxios";
import Fieldset from "components/SuiForm/components/Fieldset";
import SuiButton from "components/SuiButton";
import LabelInput from "components/SuiInput/LabelInput";
import Icon from "components/Icon";
import ImageDelete from "./components/ImageDelete";

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes loader": {
      "0%": {
        transform: "scale(1, 1)",
      },
      "50%": {
        transform: "scale(1.3)",
      },
      "100%": {
        transform: "scale(1, 1)",
      },
    },
    loading: {
      transition: ".4s linear",
      "& i": {
        animation: "$loader 1.7s linear infinite",
      },
    },
  })
);

function PropertyModel({ defaultValues, method }) {
  const [pictures, setPictures] = useState([]);
  const [defaultImage, setDefaultImage] = useState(defaultValues.defaultImage);
  const [optionsSelect, setOptionSelect] = useState(defaultValues.options); // State pour le select multiple;

  // pour le styleJs personnalise
  const classes = useStyles();

  // pour pouvoir naviger entre les different pages
  const navigate = useNavigate();

  const user = useAuthUser();

  // Permet de changer les valeurs dans le select multiple
  const handleChangeOptions = useCallback((change) => {
    setOptionSelect(change);
  }, []);

  const handleChangeDefaultImage = useCallback((imageDefault) => {
    setDefaultImage(imageDefault);
  }, []);

  // On recupere les differents données auprès du serveur ou dans le cache de React query
  const { data: city, isError: errorCities } = useQuery(["city"], () => loadAxios("cities"));
  const cities = city?.["hydra:member"] || [];
  const errorC = errorCities && "Nous parvenons pas à joindre le serveur";

  const { data: typeSal, isError: errorTypeSales } = useQuery(["type_sales"], () =>
    loadAxios("type_sales")
  );
  const typesSale = typeSal?.["hydra:member"] || [];
  const errorTS = errorTypeSales && "Nous parvenons pas à joindre le serveur";

  const { data: type, isError: errorTypes } = useQuery(["types"], () => loadAxios("types"));
  const types = type?.["hydra:member"] || [];
  const errorT = errorTypes && "Nous parvenons pas à joindre le serveur";

  const { data: option, isError: erroroptions } = useQuery(["option"], () => loadAxios("options"));
  const options = option?.["hydra:member"] || [];
  const errorO = erroroptions && "Nous parvenons pas à joindre le serveur";

  // Permet de creer un schema de validation pour le formulaire;
  const schemaPropertyCreate = yup.object({
    nameBuild: yup.string().notRequired().max(50, "Le nombre de caractère est limité à 50"),
    city: yup
      .string()
      .required("Nous avons besoin de la ville")
      .min(2, "Nous avons besoin de la ville"),
    floor: yup.number().max(100, "La valeur doit etre infrieurs a 100").default(0),
    surface: yup.number().default(0),
    numberShower: yup.number().default(0),
    numberBedroom: yup.number().default(0),
    numberKitchen: yup.number().default(0),
    price: yup.number().max(999999999).positive().required("Nous avons besoin d'un prix"),
    type: yup.string().required("Selectionner un type s'il vous plaît"),
    typeSale: yup.string().required(),
    maketable: yup.boolean(),
    address: yup.string().max(80, "Le nombre de caractères est limité à 80"),
    description: yup.string().required("Nous avons besoin d'une description ").max(255),
  });

  // Variables pour le control du formulaire avec react-hook-form
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaPropertyCreate),
  });

  // Controle des ajouts des images
  const onDrop = (picture) => {
    setPictures(pictures.concat(picture));
  };

  const querClient = useQueryClient();
  const token = useAuthHeader();
  const finalToken = token();

  // Soumission des données grâce à react query
  // eslint-disable-next-line no-unused-vars
  const { mutate, isLoading } = useMutation(
    async (data) => {
      let response;
      if (method.method === "post") {
        response = await toast.promise(
          postWithData("properties", data, method.method, finalToken),
          {
            pending: "Création en cours ...",
            success: "La propriété a été créée",
            error: "Une erreur est survenue lors de la crétion de la propriété",
          }
        );
      } else {
        response = await toast.promise(
          postWithData(`properties/${method.id}`, data, method.method, finalToken),
          {
            pending: "Modification en cours ...",
            success: "La propriété a été modifié",
            error: "Une erreur est survenue lors de la crétion de la propriété",
          }
        );
      }
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          querClient.invalidateQueries(["properties"]);
          querClient.invalidateQueries(["property"]);
          querClient.invalidateQueries(["properties-home"]);
          const slug = slugify(data.data.nameBuild, {
            replacement: "-",
          });
          // Permet d'aller directement vers la propriete
          navigate(`/properties/${slug}-${data.data.id}`, { replace: true });
        }
      },
    }
  );

  const onSubmit = (data) => {
    const form = new FormData();
    pictures.forEach((picture) => {
      form.append("file[]", picture);
    });

    // TODO: Enlever l'utilsateur par defaut,

    const optionsFinal = [];
    optionsSelect.forEach((opt) => {
      optionsFinal.push(opt.value);
    });

    const finalData = {
      nofile: {
        ...data,
        defaultImage,
        options: optionsFinal,
        user: user()["@id"],
      },
      imageForm: form,
    };
    mutate(finalData);
  };

  const dataFormProperty = [
    {
      name: "nameBuild",
      label: "Nom du batiment",
      type: "text",
      required: false,
      Placeholder: "Entrer le nom du batiment",
    },
    {
      name: "city",
      label: "Ville",
      type: "select",
      required: true,
      options: cities,
      message: errorC,
    },
    {
      name: "address",
      label: "Adresse",
      type: "text",
    },
    {
      name: "typeSale",
      label: "Type d'offre",
      type: "select",
      options: typesSale,
      message: errorTS,
    },
    {
      name: "type",
      label: "type de propriété",
      type: "select",
      options: types,
      message: errorT,
    },
    {
      name: "price",
      label: "Prix de la Propriété",
      type: "number",
      required: true,
      message: "Renseigner le prix selon le type d'offre (à louer : prix de la propriété par mois)",
    },
    {
      name: "surface",
      label: "Superficie de la propriété",
      type: "number",
    },
    {
      name: "numberBedroom",
      label: "Nombre de chambre",
      type: "number",
    },
    {
      name: "numberKitchen",
      label: "Nombre de cuisine",
      type: "number",
    },
    {
      name: "numberShower",
      label: "Nombre de toillete",
      type: "number",
    },
    {
      name: "floor",
      label: "N° d'étage",
      type: "number",
    },
    {
      name: "marketable",
      label: "Discutable ?",
      type: "switch",
      required: false,
    },
    {
      name: "options",
      label: "Bénéficie de : ",
      type: "selectMultiple",
      options,
      message: errorO,
      callback: {
        optionsSelect,
        handleChangeOptions,
      },
    },
    {
      name: "description",
      label: "Description",
      type: "textArea",
      classes: "uk-width-1-1",
      required: true,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="container my-5">
      <div className="uk-child-width-1-3@m uk-child-1-1" uk-grid="true">
        {dataFormProperty.map((field) => (
          <Fieldset
            key={field.name}
            name={field.name}
            label={field.label}
            type={field.type}
            required={field.required}
            classes={field.classes}
            control={control}
            errors={errors}
            options={field.options && field.options}
            message={field.message && field.message}
            callback={field?.callback}
          />
        ))}
        {method.method === "patch" && (
          <ImageDelete onChange={handleChangeDefaultImage} defaultImage={defaultImage} />
        )}
        <div className="uk-width-1-1">
          <LabelInput>Ajouter des images</LabelInput>
          <ImageUploader
            withIcon
            buttonText="Ajouter votre image"
            onChange={onDrop}
            imgExtenstion={[".jpg", ".gif", ".png", ".bipmap"]}
            withPreview
          />
        </div>
      </div>
      <SuiButton
        className={classes.loading}
        sx={{ mt: 4 }}
        color="secondary"
        type="submit"
        disabled={isLoading}
      >
        {method.method === "post" ? "Créer la properiété" : "Modifier la propriété"}
        {isLoading && <Icon ml={1} icon="home-lg" />}
      </SuiButton>
    </form>
  );
}

PropertyModel.defaultProps = {
  defaultValues: [{}],
  method: {
    method: "post",
    id: undefined,
  },
};

PropertyModel.propTypes = {
  defaultValues: PropTypes.arrayOf(PropTypes.objectOf),
  method: PropTypes.objectOf(),
};

export default PropertyModel;
