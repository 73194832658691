/* eslint-disable react/no-unescaped-entities */
import React from "react";
import image from "assets/images/img (4).png";
import { Box, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createStyles, makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import Fieldset from "components/SuiForm/components/Fieldset";
import SuiButton from "components/SuiButton";
import Icon from "components/Icon";
import SuiLink from "components/SuiLink/index";

import HeaderLayout from "view/layouts/HeaderLayout";
import { postAxios } from "components/hooks/hookAxios";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes loader": {
      "0%": {
        transform: "scale(1, 1)",
      },
      "50%": {
        transform: "scale(1.3)",
      },
      "100%": {
        transform: "scale(1, 1)",
      },
    },
    loading: {
      transition: ".4s linear",
      "& i": {
        animation: "$loader 1.7s linear infinite",
      },
    },
  })
);

function Registration() {
  const classes = useStyles();
  const navigate = useNavigate();

  const schemaInscription = yup.object({
    username: yup
      .string()
      .required("Veillez entrer votre nom utilisateur")
      .trim("Veillez a ne pas mettre des espaces")
      .lowercase("Ne doit pas contenir des majuscule"),
    password: yup.string().required("veillez entrer votre mot de passe"),
    name: yup.string().required("veillez entrer votre nom"),
    surname: yup.string().required("Veillez entrer votre prénom"),
    address: yup.string().email("Veillez enter une adresse mail valide"),
    apiKey: yup.string().required(),
    description: yup.string().notRequired(),
    phoneNumber: yup.string().required("Veillez entrer votre numéro de téléphone"),
  });

  const { mutate, isLoading } = useMutation(
    async (data) => {
      const response = await toast.promise(postAxios("/users", data), {
        pending: "Création de compte en cours",
        success: "Compte a été créer avec succès",
        error: {
          render({ data: promiseData }) {
            return `Une erreur car ${promiseData.message}. Veillez en choisir un autre`;
          },
        },
      });
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          navigate("/login");
        }
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      apiKey: "agent",
    },
    resolver: yupResolver(schemaInscription),
  });

  const fieldForm = [
    {
      name: "username",
      label: "nom de l'utilisateur",
      type: "text",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "name",
      label: "nom",
      type: "text",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "surname",
      label: "prénom",
      type: "text",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "address",
      label: "Adresse mail",
      type: "email",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "phoneNumber",
      label: "Numéro de téléphone",
      type: "tel",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "apiKey",
      label: "type de compte",
      type: "select",
      classes: "col-12 col-sm-6 col-md-4",
      options: [
        {
          "@id": "bailleur",
          name: "Bailleur",
        },
        {
          "@id": "agent",
          name: "Agent immobilier",
        },
      ],
    },
    {
      name: "password",
      label: "Mot de passe",
      type: "password",
      classes: "col-12 col-sm-6 col-md-4",
    },
    {
      name: "description",
      label: "Une description de vous",
      type: "textArea",
      classes: "col-12",
    },
  ];
  return (
    <Box>
      <HeaderLayout img={[{ img: image, alt: image }]} title="Inscription" />
      <Box
        className="container"
        sx={{
          display: "flex",
          justifyContent: "center",
          widht: 1,
          py: 3,
        }}
      >
        <Box component="form" onSubmit={handleSubmit(mutate)}>
          <Box className="row">
            {fieldForm.map((field) => (
              <Fieldset
                key={field.name}
                name={field.name}
                label={field.label}
                type={field.type}
                errors={errors}
                control={control}
                options={field.options}
                classes={field.classes}
              />
            ))}
          </Box>
          <Box my={3}>
            <Typography sx={{ my: 3 }} variant="caption">
              Vous avez un compte ?{" "}
              <SuiLink fontWeight="400" color2="primary" to="/login" size="large" variant="text">
                Connectez-vous ici
              </SuiLink>
            </Typography>
          </Box>
          <SuiButton
            className={classes.loading}
            sx={{
              width: { xs: 1, md: "auto" },
              mt: 2,
            }}
            size="small"
            color="secondary"
            disabled={isLoading}
            type="submit"
          >
            S'inscrire
            {isLoading && <Icon ml={1} icon="home-lg" />}
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Registration;
