import { createRefresh } from "react-auth-kit";
import axios from "axios";

const refreshApi = createRefresh({
  interval: 90010,
  refreshApiCallback: ({ refreshToken }) => {
    axios
      .post("/fipro_api/public/api/token/refresh", {
        refresh_token: refreshToken,
      })
      .then(({ data }) => ({
        isSuccess: true,
        newAuthToken: data.token,
      }))
      .catch(() => ({
        isSuccess: false,
      }));
  },
});

export default refreshApi;
