/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { v4 as uuid } from "uuid";
// import { PropTypes } from "prop-types";

import { createStyles, makeStyles, useTheme } from "@mui/styles";

import image1 from "assets/images/img (1).jpg";
import image2 from "assets/images/img (2).jpeg";
import image3 from "assets/images/img (3).jpg";
import decor from "assets/images/decor.png";

import BoxDark from "view/layouts/BoxDark/BoxDark";
import Search from "view/layouts/Search";

const imageHeader = [
  {
    img: image1,
    alt: "image 1",
  },
  {
    img: image2,
    alt: "image 1",
  },
  {
    img: image3,
    alt: "image 1",
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes textanim": {
      "35%": {
        content: "'louer'",
      },
      "65%": {
        content: "'vendre'",
      },
    },
    header: {
      height: "100%",
      position: "relative",
      "& ul": {
        height: "100% !important",
        minHeight: "0px !important",
      },
    },
    content: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: 9,
    },
    title: {
      transition: ".3s linear",
      "&::after": {
        content: '"trouver"',
        animation: "$textanim 9s linear infinite",
      },
    },
  })
);

function ContentHeader({ classes }) {
  const theme = useTheme();
  const matche = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box className={classes.content}>
      <Box sx={{ pt: { md: 18, xs: 12 } }} className="container">
        <div className="uk-child-width-1-2@m" uk-grid="true">
          <Box
            className="animate__animated animate__fadeIn animate__slow"
            sx={{
              pt: { lg: 3, xs: 1 },
              textAlign: { lg: "left", xs: "center" },
            }}
          >
            <Typography
              fontSize={matche ? 60 : 30}
              color="white.main"
              variant="h1"
              fontWeight="100"
            >
              La meilleure façon de <span className={classes.title} /> votre maison !
            </Typography>
            <Typography
              sx={{ display: { lg: "block", xs: "none" } }}
              mt={2}
              variant="body2"
              color="white.main"
              fontWeight="100"
            >
              FIYPRO est un site qui vous permet de trouver facilement des chambres, des
              appartements sans avoir à quitter votre canapé.
            </Typography>
            <Typography
              sx={{ mt: { lg: 4, xs: 1 } }}
              fontWeight={100}
              variant={matche ? "h1" : "h2"}
              color="secondary.focus"
            >
              Commencer
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box className="search-box animate__animated animate__fast animate__bounceInRight animate__delay-1s uk-box-shadow-medium uk-box-shadow-hover-xlarge">
              <Search />
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

function Header() {
  const classes = useStyles();
  return (
    <Box
      sx={{ height: { lg: "130vh", xs: "758px" }, position: "relative", transition: ".4s linear" }}
    >
      <div className={classes.header} tabIndex="-1" uk-slideshow="animation: fade; autoplay: true">
        <ul className="uk-slideshow-items">
          {imageHeader.map((image) => (
            <Box component="li" key={uuid()}>
              <Box
                component="div"
                className="uk-position-cover  uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
              >
                <img alt={image.alt} src={image.img} uk-cover="true" />
              </Box>
            </Box>
          ))}
        </ul>
      </div>
      <ContentHeader classes={classes} />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: { md: "40%", xs: "45%" },
          diplay: "flex",
          alignItems: "center",
          zIndex: 5,
        }}
        src={decor}
        alt={decor}
        component="img"
      />
      <BoxDark />
    </Box>
  );
}

export default React.memo(Header);
