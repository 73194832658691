import React from "react";
import { Link } from "react-router-dom";
import SuiButton from "components/SuiButton";
import { createStyles, makeStyles } from "@mui/styles";
import { PropTypes } from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    white: {
      color: `${theme.palette.white.main} !important`,
      "&:hover": {
        color: theme.palette.white.main,
      },
    },

    primary: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },

    secondary: {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  })
);

function SuiLink({ variant, color, color2, to, children, size, fontWeight, ...rest }) {
  const classes = useStyles();
  return (
    <SuiButton
      size={size}
      variant={variant}
      color={color}
      {...rest}
      sx={{
        fontWeight: `${fontWeight} !important`,
      }}
    >
      <Link className={classes[color2]} to={to}>
        {children}
      </Link>
    </SuiButton>
  );
}

SuiLink.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  to: "/home",
  color2: "white",
  fontWeight: "700",
};

SuiLink.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  color2: PropTypes.oneOf(["white", "primary", "secondary", "dark"]),
  to: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SuiLink;
