import React from "react";
import { Box, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { createStyles, makeStyles } from "@mui/styles";

import image1 from "assets/images/footer.jpg";
import image2 from "assets/images/img (5).jpg";
import image3 from "assets/images/img (4).png";
import decor from "assets/images/decor-left.png";

import BoxDark from "view/layouts/BoxDark/BoxDark";

const imageHeader = [
  {
    img: image1,
    alt: "image 1",
  },
  {
    img: image2,
    alt: "image 1",
  },
  {
    img: image3,
    alt: "image 1",
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: "100%",
      "& ul": {
        height: "100% !important",
        minHeight: "0px !important",
      },
    },
  })
);
function SectionSubmit() {
  const classes = useStyles();

  return (
    <Box my={7}>
      <Box sx={{ height: { md: 400, xs: 300 }, position: "relative" }}>
        <div
          className={[classes.header, "uk-position-relative"].join(" ")}
          tabIndex="-1"
          uk-slideshow="animation: fade; autoplay: true"
        >
          <ul className="uk-slideshow-items">
            {imageHeader.map((image) => (
              <Box component="li" key={uuid()}>
                <Box
                  component="div"
                  className="uk-position-cover  uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
                >
                  <img alt={image.alt} src={image.img} uk-cover="true" />
                </Box>
              </Box>
            ))}
          </ul>
        </div>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            height: "100%",
            width: { md: "50%", xs: "60%" },
            padding: { md: 9, xs: 6 },
            zIndex: 9,
          }}
        >
          <Typography variant="h2" color="white.main" fontWeight={100}>
            Le meilleur partenaire pour trouver ou de vendre une propriété.
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: { xs: "40%", md: "30%" },
            height: { md: "60%", xs: "45%" },
            diplay: "flex",
            alignItems: "center",
            zIndex: 9,
          }}
          component="img"
          src={decor}
          alt={decor}
        />
        <BoxDark />
      </Box>
    </Box>
  );
}

export default SectionSubmit;
