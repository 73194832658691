/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-globals */
import { Box, Typography } from "@mui/material";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createStyles, makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

// import image from "assets/images/bg-feature.jpg";
import Icon from "components/Icon";
import { bearerAxios } from "components/hooks/hookAxios";
import LabelInput from "components/SuiInput/LabelInput";
import SuiButton from "components/SuiButton";
import { useAuthHeader } from "react-auth-kit";

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      height: "300px",
      width: "350px",
    },
    supp: {
      position: "absolute",
      top: "0",
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: theme.palette.error.main,
      border: "3px solid white",
    },
  })
);

// eslint-disable-next-line react/prop-types
function ImageDelete({ onChange, defaultImage }) {
  const { data } = useQuery(["property"]);
  const property = data || [];
  const classes = useStyles();

  const token = useAuthHeader();
  const finalToken = token();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    async (data) => {
      if (defaultImage === data.name) {
        if (property.images.length === 1) {
          onChange(null);
        } else {
          property.images.forEach((image1) => {
            if (image1.name !== data.name) {
              onChange(image1.name);
            }
          });
        }
      }
      const response = await bearerAxios(`images/${data.id}`, finalToken, "DELETE");
      const { id } = data;
      return {
        ...response,
        id,
      };
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          queryClient.setQueryData(["property"], (propertyClient) => ({
            ...property,
            images: propertyClient.images.filter((i) => i.id !== data.id),
          }));
          toast.success("L'image a été correctement supprimé", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast.error("Une erreur est survenue lors de la suppression", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      },
    }
  );

  // eslint-disable-next-line react/prop-types
  const Msg = ({ closeToast, id, name }) => {
    return (
      <Box>
        <Typography fontWeight="bold" textAlign="center" variant="body2">
          Confirmer la suppression
        </Typography>
        <Box display="flex" justifyContent="space-around">
          <SuiButton color="error" size="small" onClick={() => mutate({ id, name })}>
            supprimer
          </SuiButton>
          <SuiButton color="secondary" size="small" onClick={closeToast}>
            annuler
          </SuiButton>
        </Box>
      </Box>
    );
  };

  const handleDeleteImage = (id, name) => {
    toast.warn(<Msg id={id} name={name} />, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
    });
  };

  return (
    <Box width={1}>
      <LabelInput>Supprimer des images de la collection</LabelInput>
      <div className="row">
        {property.images.map((image) => (
          <div key={image.id} className="col-12 my-3 col-sm-auto">
            <Box className={[classes.image, "uk-cover-container"].join(" ")}>
              <img
                src={`/fipro_api/public/images/properties-images/${image.name}`}
                alt={image.name}
                uk-cover="true"
              />
              <Box
                data-image={image.id}
                data-imageName={image.name}
                className={classes.supp}
                onClick={() => handleDeleteImage(image.id, image.name)}
              >
                <Icon color="white.main" icon="times" type="l" />
              </Box>
            </Box>
            <SuiButton
              size="small"
              sx={{ mt: 2 }}
              disabled={defaultImage === image.name}
              color="primary"
              onClick={() => onChange(image.name)}
            >
              {defaultImage === image.name
                ? "Image de couverture"
                : "mettre cette image en couverture"}
            </SuiButton>
          </div>
        ))}
      </div>
    </Box>
  );
}

export default ImageDelete;
