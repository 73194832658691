const LINKFOOTER = [
  {
    name: "à propos de nous",
    route: "/aboutUs",
  },
  {
    name: "agents",
    route: "/agents",
  },
  {
    name: "maketplace",
    route: "/maket",
  },
  {
    name: "contactez nous",
    route: "/contactUs",
  },
];

const INFO = [
  {
    icon: "map-marker-alt",
    content: "Bangangté",
  },
  {
    icon: "phone",
    content: "(+237) 691 519 347",
  },
];

export { LINKFOOTER, INFO };
