import React from "react";
import { useQuery } from "react-query";
import { loadAxios } from "components/hooks/hookAxios";
import { useFilterProperty, setCityFilterProperty } from "services/context/filter";

import FilterItems from "./FilterItems";
import CheckBox from "./CheckBox";

function FilterCity() {
  const [filterProperty, dispatch] = useFilterProperty();
  const { cityFilter } = filterProperty;

  const { data } = useQuery(["city"], () => loadAxios("cities"));
  const cities = data?.["hydra:member"] || [];

  const handleChange = (e) => {
    if (e.target.checked) {
      setCityFilterProperty(dispatch, {
        name: [...cityFilter.name, e.target.dataset.name],
        data: [...cityFilter.data, e.target.value],
      });
    } else {
      setCityFilterProperty(dispatch, {
        name: cityFilter.name.filter((c) => c !== e.target.dataset.name),
        data: cityFilter.data.filter((c) => c !== e.target.value),
      });
    }
  };

  return (
    <FilterItems content={cityFilter.name} id="city" label="emplacement de la propriété">
      <div className="inputCheckbox">
        {cities &&
          cities.map((item) => (
            <div key={item.id} className="my-3">
              <CheckBox
                data={cityFilter.data}
                name={item.name}
                id={item.id}
                handleChange={handleChange}
              />
              <label className="" htmlFor={item.id}>
                {item.name}
              </label>
            </div>
          ))}
      </div>
    </FilterItems>
  );
}

export default React.memo(FilterCity);
