/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { AppBar, Box, Link as LinkM } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Link, NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";

import fiypro from "assets/images/fiypro (Petite).png";

import Icon from "components/Icon/index";
import dataNav from "routes/components/dataRoutes";
import SuiButton from "components/SuiButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      color: `${theme.palette.secondary.main}!important`,
      fontWeight: "300 !important",
    },
    noActive: {
      fontWeight: "500 !important",
      transition: ".3s",
      "&:hover": {
        fontWeight: "300 !important",
        color: `${theme.palette.secondary.focus} !important`,
      },
    },
    mainNavbar: {
      display: "flex",
      alignItems: "center",
      "& .nav-item": {
        display: "inline-block",
        margin: "0px 10px",
        "& a": {
          color: "white",
          fontFamily: "openSans",
          fontWeight: "500",
          fontSize: "14px",
          textTransform: "uppercase",
        },
      },
    },
    drop: {
      position: "relative",
      "&:hover .navbar-dropdown": {
        opacity: 1,
        height: "auto",
        zIndex: 1111,
        transition: "0.4s",
        transform: "translateY(40px)",
      },

      "& .navbar-dropdown": {
        background: theme.palette.primary.main,
        padding: theme.spacing(1, 2),
        top: "0px",
        left: "0",
        position: "absolute",
        width: 250,
        opacity: 0,
        transition: ".4s",
        zIndex: -999,
        "& ul": {
          padding: 0,
          marging: 0,
          "& .drop-item": {
            listStyleType: "none",
            "& a": {
              color: theme.palette.white.main,
            },
          },
        },
      },
    },
  })
);

function NavBar() {
  const classes = useStyles();

  return (
    <AppBar position="static">
      <Box
        className="container"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          background: (theme) => theme.palette.transparent.main,
          height: { md: 100, xs: 60 },
        }}
      >
        <Box>
          <SuiButton
            sx={{ display: { md: "none", xs: "block" } }}
            variant="text"
            size="large"
            uk-toggle="target: #offcanvas"
          >
            <Icon color="white.main" fontSize={30} type="l" icon="bars" />
          </SuiButton>
        </Box>
        <Box>
          <Link to="/home">
            <Box
              sx={{
                height: { md: 75, xs: 50 },
              }}
              component="img"
              src={fiypro}
              alt="fiypro"
            />
          </Link>
        </Box>
        <Box>
          <SuiButton
            sx={{ display: { md: "none", xs: "block" } }}
            variant="text"
            size="large"
            data-bs-toggle="modal"
            data-bs-target="#PropertiesSearchModal"
          >
            <Icon color="white.main" fontSize={30} type="l" icon="search" />
          </SuiButton>
        </Box>

        {/* DESKTOP SECTION */}

        <Box
          sx={{ display: { md: "flex", xs: "none" } }}
          className="uk-navbar-left uk-flex-middle uk-flex-between"
        >
          <Box className={classes.mainNavbar} comonent="ul">
            {dataNav.map((route) =>
              route.menuItem ? (
                <Box key={uuid()} className={[classes.drop, "nav-item"]} component="li">
                  <LinkM href="#">
                    {route.name}
                    <Icon ml={1} type="l" icon="chevron-down" />
                  </LinkM>
                  <Box sx={{ boxShadow: 5 }} className="navbar-dropdown">
                    <Box component="ul">
                      {route.menuItem.map((item) => (
                        <Box key={uuid()} className="drop-item" component="li">
                          <NavLink
                            className={({ isActive }) =>
                              isActive ? classes.active : classes.noActive
                            }
                            to={item.route}
                          >
                            {item.name}
                          </NavLink>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box key={uuid()} className="nav-item" component="li">
                  <NavLink
                    className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                    to={route.route}
                  >
                    {route.name}
                  </NavLink>
                </Box>
              )
            )}
          </Box>
        </Box>

        {/* MOBILE  MENU SECTION */}

        <div id="offcanvas" uk-offcanvas="mode: reveal; overlay: true">
          <div className="uk-offcanvas-bar uk-flex uk-flex-column">
            <ul className="uk-nav uk-nav-primary uk-nav-center  uk-margin-auto-vertical">
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                  to="/home"
                >
                  Accueil
                </NavLink>
              </li>
              <li className="uk-nav-divider" />
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                  to="/properties"
                >
                  Propriétés
                </NavLink>
              </li>
              <li className="uk-nav-divider" />
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                  to="/agents"
                >
                  Agents
                </NavLink>
              </li>
              <li className="uk-nav-divider" />
              <li className="uk-parent">
                <div href="#toggle-animation" className="item-button">
                  Utilisateur <i className="fal fa-chevron-down ps-1" />
                </div>
                <ul id="toggle-animation" className="uk-nav-sub">
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                      to="/profil/properties"
                    >
                      Mes propriétés
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                      to="/properties/create"
                    >
                      Soumèttre une propriété
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                      to="/profil"
                    >
                      Profil
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="uk-nav-divider" />
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                  to="/maket"
                >
                  Service d'achat
                </NavLink>
              </li>
              <li className="uk-nav-divider" />
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? classes.active : classes.noActive)}
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              <li className="uk-nav-divider" />
            </ul>
          </div>
        </div>
      </Box>
    </AppBar>
  );
}

export default NavBar;
