/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import slugify from "slugify";

import light from "assets/images/no-img.jpg";

import Icon from "components/Icon";
// import { PropTypes } from "prop-types";

function ItemProperty(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  // TODO: mettre a jour le lien pour la production
  const name = props.nameBuild && props.nameBuild !== "" ? props.nameBuild : "pas de nom";
  const slug = slugify(name);
  return (
    <Link to={`/properties/${slug}-${props.id}`}>
      <div className="uk-card uk-box-shadow-large card-item">
        <div className="uk-card-media-top">
          <div
            className="image-uk-card uk-position-relative uk-visible-toggle uk-light"
            tabIndex="-1"
            uk-slideshow="animation: push"
          >
            <ul className="uk-slideshow-items">
              <li>
                <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                  <img
                    className="image-uk-card"
                    src={
                      props.defaultImage
                        ? `/fipro_api/public/images/properties-images/${props.defaultImage}`
                        : light
                    }
                    alt={props.defaultImage}
                    uk-cover="true"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="hover" />
          <div className="option uk-box-shadow-medium">{props.typeSale}</div>
        </div>
        <div className="card-description">
          <Box>
            <Typography variant={matches ? "h4" : "h6"}>
              {props.nameBuild ? props.nameBuild : "Pas de nom"}
            </Typography>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Icon icon="map-marker-alt" color="secondary.focus" mr={1} />
              <Typography fontWeight={600} variant="body1">
                {props.city}
              </Typography>
              ,<Typography variant="body1">{props.address}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Icon icon="home" mr={1} color="secondary.focus" />
            {props.type}
          </Box>
          <div className="uk-divider" />
          <div className="card-price">
            {new Intl.NumberFormat("cm-CM", { style: "currency", currency: "XFA" }).format(
              props.price
            )}
            {props.typeSale !== "A vendre" && <span className="card-price-detail">/mois</span>}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ItemProperty;
