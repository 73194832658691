/* eslint-disable no-nested-ternary */
import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useAuthUser, useAuthHeader } from "react-auth-kit";

import image from "assets/images/img (4).png";

import { bearerAxios } from "components/hooks/hookAxios";

import HeaderLayout from "view/layouts/HeaderLayout";
import Loading from "view/layouts/Loading";
import ItemPropertyProfil from "view/layouts/ItemPropertyProfil";
import Error from "view/layouts/Error";

function ProfilProperties() {
  const img = useMemo(() => [{ img: image, alt: image }], [image]);
  const user = useAuthUser();
  const token = useAuthHeader();
  const finalToken = token();
  // eslint-disable-next-line no-unused-vars
  const { data, isLoading, isError } = useQuery(["profilProperties"], () =>
    bearerAxios("properties/profil", finalToken, "get")
  );
  const properties = data?.data?.["hydra:member"] || [];
  return (
    <Box>
      <HeaderLayout
        title={`Toutes les propriétés créées par ${user() && user().username}`}
        img={img}
      />
      <Box className="container my-5">
        {isLoading ? (
          <Box height={300} width={1}>
            <Loading />
          </Box>
        ) : isError ? (
          <Error />
        ) : (
          <div className="row">
            {properties.length !== 0 &&
              properties.map((property) => (
                <ItemPropertyProfil
                  id={property.id}
                  image={property.defaultImage}
                  nameBuild={property.nameBuild}
                  price={property.price}
                  address={property.address}
                  sold={property.sold}
                />
              ))}
          </div>
        )}
      </Box>
    </Box>
  );
}

export default ProfilProperties;
