/* eslint-disable no-shadow */
import React from "react";
import { Box, Typography, alpha, useMediaQuery } from "@mui/material";
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

import fiypro from "assets/images/fiypro (Petite).png";
import decor from "assets/images/decor-alt.png";
// import background from "assets/images/footer.jpg";

import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Icon from "components/Icon";
import SuiLink from "components/SuiLink";
import { LINKFOOTER, INFO } from "./components/dataFooter";

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.black.main,
      backgroundImage: `url(${decor})`,
      backgroundPosition: "top left",
      backgroundRepeat: "no-repeat",
      position: "relative",
      width: "100%",
      transition: ".5s linear",
    },
    link: {
      color: "white",
      fontFamily: "openSans",
      transition: ".4s linear",
      fontWeight: "100 !important",
      fontSize: 16,
      "&:hover": {
        color: theme.palette.secondary.focus,
      },
    },
  })
);

function Footer() {
  const classes = useStyles();
  const date = new Date();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  if (!matches) {
    return (
      <Box
        component="footer"
        sx={{ backgroundSize: { md: "400px", sm: "300px", xs: "200px" } }}
        className={classes.footer}
      >
        <Box className="container">
          <Box
            sx={{
              paddingTop: { md: 10, sm: 10, xs: 10 },
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ height: { md: 170, sm: 120, xs: 95 } }} component="img" src={fiypro} />
          </Box>
          <Box
            sx={{
              pt: 4,
              pb: 2,
              mt: 2,
              borderTop: (theme) => `1px solid ${alpha(theme.palette.grey[200], 0.4)}`,
              borderBottom: (theme) => `1px solid ${alpha(theme.palette.grey[200], 0.4)}`,
            }}
          >
            <div className="uk-child-width-expand uk-grid-small" uk-grid="true">
              <Box className="uk-width-1-3@m uk-width-1-2@s uk-width-1-1">
                <Typography pb={2} variant="h4" color="white.main" fontWeight="100">
                  Reste informé
                </Typography>
                <Typography variant="body2" color="white.main" fontWeight="100">
                  Sousc
                </Typography>
                <Box component="form" display="flex">
                  <SuiInput
                    icon={{ component: "envelope", direction: "left" }}
                    placeholder="Votre adresse mail"
                    required
                  />
                  <Box ml={2}>
                    <SuiButton type="submit" color="secondary">
                      <Icon fontSize={20} icon="paper-plane" />
                    </SuiButton>
                  </Box>
                </Box>
              </Box>
              {/** Reste informe */}
              <Box className="uk-width-1-2@s uk-width-1-1 uk-width-expand@m">
                <Typography pb={2} variant="h4" color="white.main" fontWeight={100}>
                  Autre pages
                </Typography>
                <Box sx={{ padding: "0 !important" }} component="ul">
                  {LINKFOOTER.map((link) => (
                    <Box key={uuid()} component="li">
                      <Link className={classes.link} to={link.route}>
                        {link.name}
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
              {/** Autre page END */}
              <Box className="uk-width-1-2@s uk-width-1-1 uk-width-expand@m">
                <Typography pb={2} variant="h4" fontWeight={100} color="white.main">
                  Liens rapides
                </Typography>
              </Box>
              {/** Lien rapide END */}
              <Box className="uk-width-1-2@s uk-width-1-1 uk-width-expand@m">
                <Typography pb={2} variant="h4" color="white.main" fontWeight={100}>
                  Autre informations
                </Typography>
                <Box sx={{ padding: "0 !important" }} component="ul">
                  {INFO.map((info) => (
                    <Box key={uuid()} display="flex" alignItems="center" component="li">
                      <Icon width={20} icon={info.icon} color="secondary.focus" mr={2} />
                      <Typography color="white.main" variant="body2" fontWeight={100}>
                        {info.content}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              {/** Autre informations END */}
            </div>
          </Box>
          <Box py={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" color="white.main" fontWeight={100}>
              © {date.getFullYear()} tous droits reservés
            </Typography>
            <Box display="flex">
              <SuiLink color2="white" variant="text" size="large">
                <Icon type="b" icon="facebook-f" />
              </SuiLink>
              <SuiLink color2="white" variant="text" size="large">
                <Icon type="b" icon="twitter" />
              </SuiLink>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <div className="footer-mobile">
      <div className="container">
        <div className="section-content  uk-flex uk-flex-around">
          <div>® FIYPRO</div>
          <div>{"<GenesisDev />"}</div>
          <div>{date.getFullYear()}</div>
          <div>
            <a href="sgfsg">confidentialité</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
