import React from "react";
// import { useFilterProperty } from "services/context/filter";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  useFilterProperty,
  setUrlParamsFilterProperty,
  resetFilterProperty,
} from "services/context/filter";

import SuiButton from "components/SuiButton/index";
import FilterCity from "./components/FilterCity";
import FilterType from "./components/FilterType";
import FilterOption from "./components/FilterOption";
import FilterPrice from "./components/FilterPrice";

function Search() {
  const [filterProperty, dispatch] = useFilterProperty();
  const { cityFilter, typeFilter, optionFilter, priceFilter } = filterProperty;
  const navigate = useNavigate();
  const handleFilter = () => {
    let params = "";
    if (cityFilter) {
      cityFilter.data.forEach((city) => {
        params += `&city[]=${city}`;
      });
    }

    if (typeFilter) {
      typeFilter.data.forEach((type) => {
        params += `&type[]=${type}`;
      });
    }

    if (optionFilter) {
      optionFilter.data.forEach((option) => {
        params += `&options[]=${option}`;
      });
    }

    if (priceFilter[0] !== "") {
      params += `&price[gte]=${priceFilter[0]}`;
    }

    if (priceFilter[1] !== "") {
      params += `&price[lte]=${priceFilter[1]}`;
    }

    setUrlParamsFilterProperty(dispatch, params);
    localStorage.setItem("filterProperty", JSON.stringify(filterProperty));
    navigate("/properties");
  };

  const handleReset = () => {
    resetFilterProperty(dispatch);
    localStorage.clear();
  };

  return (
    <Box compoent="form">
      <FilterCity />
      <FilterType />
      <FilterOption />
      <FilterPrice />
      <Box className="uk-flex uk-flex-around">
        <SuiButton
          onClick={handleFilter}
          color="secondary"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Rechercher
        </SuiButton>
        <SuiButton onClick={handleReset} color="error" data-bs-dismiss="modal" aria-label="Close">
          Réinitialiser
        </SuiButton>
      </Box>
    </Box>
  );
}

export default Search;
