import React from "react";
import { Box } from "@mui/material";
import TopBar from "./components/topBar/TopBar";
import NavBar from "./components/NavBar/NavBar";

function Header() {
  return (
    <Box position="absolute" top="0" width={1} sx={{ zIndex: 100000 }}>
      <TopBar />
      <NavBar />
    </Box>
  );
}

export default Header;
