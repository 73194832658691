import { createTheme } from "@mui/material";

import boxShadow from "assets/theme/functions/boxShadow";
import hexToRgb from "assets/theme/functions/hexToRgb";
import linearGradient from "assets/theme/functions/linearGradient";
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import button from "assets/theme/components/button";
import appBar from "assets/theme/components/appBar";
import select from "assets/theme/components/form/select";
import formControlLabel from "assets/theme/components/form/formControlLabel";
import formLabel from "assets/theme/components/form/formLabel";
import checkbox from "assets/theme/components/form/checkbox";
import radio from "assets/theme/components/form/radio";
import autocomplete from "assets/theme/components/form/autocomplete";
import input from "assets/theme/components/form/input";
import inputBase from "assets/theme/components/form/inputBase";
import boxShadows from "assets/theme/base/boxShadows";
import breakpoints from "./base/breakpoints";
import globals from "./base/globals";
import colors from "./base/colors";

export default createTheme({
  breakpoints: { ...breakpoints },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  palette: { ...colors },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
    MuiInputBase: { ...inputBase },
    MuiButton: { ...button },
    MuiAppBar: { ...appBar },
    MuiSelect: { ...select },
    MuiFormControlLabel: { ...formControlLabel },
    MuiFormLabel: { ...formLabel },
    MuiCheckbox: { ...checkbox },
    MuiRadio: { ...radio },
    MuiAutocomplete: { ...autocomplete },
    MuiInput: { ...input },
    MuiOutlinedInput: { ...input },
    MuiFilledInput: { ...input },
  },
});
