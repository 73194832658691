import pxToRem from "assets/theme/functions/pxToRem";

// const { transparent } = colors;

const select = {
  styleOverrides: {
    select: {
      alignItems: "center",
      padding: `0 ${pxToRem(12)} !important`,
      width: "100% !important",

      "& .Mui-selected": {
        width: "100% !important",
      },
    },

    selectMenu: {
      // background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },
  },
};

export default select;
