/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
import { PropTypes } from "prop-types";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import slugify from "slugify";

import light from "assets/images/no_user.jpg";

import SuiLink from "components/SuiLink";
import Icon from "components/Icon";

function ItemAgent({ name, tel, email, id }) {
  const slug = slugify(name);
  return (
    <div className="item-agent">
      <div className="border uk-card card-agent-home">
        <div className="uk-card-media-top  uk-inline-clip uk-transition-toggle" tabIndex="0">
          <img className="img-card" src={light} alt="" />
          <div className="hover uk-transition-slide-bottom uk-position-bottom">
            <Box>
              <div className="job">Etudiant</div>
              <Box display="flex" alignItems="center">
                <SuiLink size="large" variant="text" to="/" color="white">
                  <Icon icon="envelope" />
                </SuiLink>
                <SuiLink size="large" variant="text" to="/" color="white">
                  <Icon type="b" icon="twitter" />
                </SuiLink>
                <SuiLink size="large" variant="text" to="/" color="white">
                  <Icon type="b" icon="facebook-f" />
                </SuiLink>
                <SuiLink size="large" variant="text" to="/" color="white">
                  <Icon type="b" icon="linkedin-in" />
                </SuiLink>
              </Box>
            </Box>
          </div>
        </div>
        <div className="card-description">
          <p className="name-agent">{name}</p>
          <p className="tel-agent">
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <p className="tel-agent">
            <a href={`tel:+237${tel}`}>(+237) {tel}</a>
          </p>
          <p className="detail">
            <Link className="uk-text-muted" to={`/profil/${slug}-${id}`}>
              voir mes propriétés
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

ItemAgent.defaultProps = {
  email: "",
  name: "",
};

ItemAgent.propTypes = {
  name: PropTypes.string,
  tel: PropTypes.string.isRequired,
  email: PropTypes.string,
  id: PropTypes.number.isRequired,
};

export default ItemAgent;
