import axios from "axios";

const dev = "/fipro_api/public/api";
// const dev = "/fipro_api/public/api";
const api = axios.create({
  baseURL: dev,
  responseType: "ld+json",
  headers: {
    Accept: "application/ld+json",
  },
});

export async function paginatedAxios(url, params, setTotal, setNext, page = 1) {
  const param = new URLSearchParams();
  param.set("page", page);
  const response = await api.get(`${url}?${param.toString()}${params}`);
  setTotal(response.data["hydra:totalItems"]);
  if (response.data["hydra:view"]["hydra:next"]) {
    setNext(true);
  } else {
    setNext(false);
  }
  return response.data["hydra:member"];
}

export async function loadAxios(url) {
  const response = await api.get(url);
  return response.data;
}

export async function loadBearerAxios(url, token) {
  const response = await api({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
  })
    .then(({ data }) => ({
      data,
      isSuccess: true,
    }))
    .catch((e) => {
      throw new Error(e);
    });
  return response;
}

export async function bearerAxios(url, token, method = "post", dataPost = "null") {
  const response = await api({
    method,
    headers: {
      Authorization: token,
      "Content-Type": method === "patch" ? "application/merge-patch+json" : "application/json",
    },
    url,
    data: dataPost,
  })
    .then(({ data }) => ({
      data,
      isSuccess: true,
    }))
    .catch(() => ({
      isSuccess: false,
    }));

  return response;
}

export async function updateAxios(url, dataUpdate) {
  const response = await api({
    method: "patch",
    headers: { "Content-Type": "application/merge-patch+json" },
    url,
    data: dataUpdate,
  })
    .then(({ data }) => ({
      data,
      isSuccess: true,
    }))
    .catch(() => ({
      isSuccess: false,
    }));
  return response;
}

export async function postAxios(url, dataPost) {
  const response = await api
    .post(url, dataPost)
    .then(({ data }) => ({
      data,
      isSuccess: true,
    }))
    .catch((e) => {
      let error = e;
      if (error?.response.data["hydra:description"]) {
        error = error?.response.data["hydra:description"];
      }
      throw new Error(error);
    });
  return response;
}

export async function loginAxios(data) {
  let responseUser;
  const responseToken = await postAxios("login", data);
  if (responseToken.isSuccess) {
    responseUser = await loadBearerAxios("connected", responseToken.data?.token);
  }
  return {
    isSuccess: responseUser.isSuccess,
    token: responseToken.data,
    user: responseUser.data,
  };
}

async function postfile(url, dataForm) {
  const response = await api({
    url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data: dataForm,
  })
    .then(({ data }) => ({
      data,
      isSuccess: true,
    }))
    .catch((e) => {
      throw new Error(e);
    });

  return response;
}

export async function postWithData(url, data, method, token) {
  let responseData = await bearerAxios(url, token, method, data.nofile);
  if (responseData.isSuccess && data.imageForm) {
    let urlImage = `${url}/image`;
    if (method === "post") {
      urlImage = `${url}/${responseData.data.id}/image`;
    }
    const result = await postfile(urlImage, data.imageForm);
    if (result.isSuccess) {
      responseData = {
        ...responseData,
        data: { ...responseData.data, images: result.data.images },
      };
    }
  }
  if (!responseData.isSuccess) {
    throw new Error("Une erreur est survenue");
  }
  return responseData;
}

export async function deleteAxios(url) {
  const response = await api({
    method: "DELETE",
    url,
  })
    .then(() => ({
      isSuccess: true,
    }))
    .catch((e) => {
      throw new Error(e);
    });
  return response;
}
