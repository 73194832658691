import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useIsAuthenticated } from "react-auth-kit";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";

import theme from "assets/theme/theme";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";
import "./assets/css/reponsive.css";
import "animate.css";

import AllRoutes from "routes";

import { FilterPropertyProvider } from "services/context/filter";

import useInterval from "components/hooks/useInterval";
import refreshToken from "components/refresh";

import ModalSearch from "view/layouts/ModalSearch";
import ToTop from "view/layouts/ToTop";
import Footer from "view/layouts/Footer";
import Header from "view/layouts/Header";

function App() {
  const { pathname } = useLocation();
  const [cookie, setCookie] = useCookies(["_auth"]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const isAuth = useIsAuthenticated();
  const isAuthenticated = isAuth();

  if (isAuthenticated && !localStorage.getItem("refresh")) {
    refreshToken({ cookie, setCookie });
    localStorage.setItem("refresh", "yes");
  }

  useInterval(
    () => refreshToken({ cookie, setCookie, isAuthenticated }),
    isAuthenticated ? 72000 : null
  );

  return (
    <ThemeProvider theme={theme}>
      <FilterPropertyProvider>
        <CssBaseline />
        <ModalSearch />
        <Header />
        <AllRoutes />
        <Footer />
        <ToTop />
        <ToastContainer autoClose={2000} position="top-center" />
      </FilterPropertyProvider>
    </ThemeProvider>
  );
}

export default App;
