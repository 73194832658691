/* eslint-disable react/no-unescaped-entities */
import React from "react";
import image from "assets/images/img (4).png";
import { Box, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createStyles, makeStyles } from "@mui/styles";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useSignIn } from "react-auth-kit";
import { useLocation, useNavigate } from "react-router-dom";

import { loginAxios } from "components/hooks/hookAxios";
import Fieldset from "components/SuiForm/components/Fieldset";
import SuiButton from "components/SuiButton";
import Icon from "components/Icon";
import SuiLink from "components/SuiLink";

import HeaderLayout from "view/layouts/HeaderLayout";

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes loader": {
      "0%": {
        transform: "scale(1, 1)",
      },
      "50%": {
        transform: "scale(1.3)",
      },
      "100%": {
        transform: "scale(1, 1)",
      },
    },
    loading: {
      transition: ".4s linear",
      "& i": {
        animation: "$loader 1.7s linear infinite",
      },
    },
  })
);

function Login() {
  const classes = useStyles();

  const schemaLogin = yup.object({
    username: yup.string().required("Veillez entrer votre nom d'utilisateur"),
    password: yup.string().required("Veillez entre votre mot de passe"),
  });

  const defaultValues = {
    username: "",
    password: "",
  };

  const signIn = useSignIn();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: "/home" };

  const { mutate, isLoading } = useMutation(
    async (data) => {
      const response = await toast.promise(loginAxios(data), {
        pending: "Connexion en cours",
        success: {
          render({ data: dataPromise }) {
            return `Bienvenue ${dataPromise.user.surname}`;
          },
        },
        error: "Une erreur c'est produite !",
      });
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          signIn({
            token: data.token.token,
            expiresIn: 1000000,
            tokenType: "Bearer",
            authState: data.user,
            refreshToken: data.token.refresh_token,
            refreshTokenExpireIn: 6480000,
          });
          navigate(from);
        }
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schemaLogin),
  });

  const fieldForm = [
    {
      name: "username",
      label: "Nom utilisateur",
      type: "text",
    },
    {
      name: "password",
      label: "mot de passe",
      type: "password",
    },
  ];

  return (
    <Box>
      <HeaderLayout img={[{ img: image, alt: image }]} title="Connexion" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          widht: 1,
          py: 3,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(mutate)}
          sx={{
            width: { sm: 400, xs: 340 },
            height: { xs: "90vh", sm: 300 },
          }}
        >
          {fieldForm.map((field) => (
            <Fieldset
              key={field.name}
              name={field.name}
              label={field.label}
              type={field.type}
              errors={errors}
              control={control}
            />
          ))}
          <Box my={3}>
            <Typography sx={{ my: 3 }} variant="caption">
              Vous n'avez pas de compte ?{" "}
              <SuiLink
                fontWeight="400"
                color2="primary"
                to="/registration"
                size="large"
                variant="text"
              >
                Inscrivez-vous ici
              </SuiLink>
            </Typography>
          </Box>
          <SuiButton
            className={classes.loading}
            sx={{
              width: 1,
              mt: 2,
            }}
            size="small"
            color="secondary"
            disabled={isLoading}
            type="submit"
          >
            S'identifier
            {isLoading && <Icon ml={1} icon="home-lg" />}
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
