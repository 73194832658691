/* eslint-disable no-nested-ternary */
import React from "react";
import { Box } from "@mui/material";
import { v4 as uuid } from "uuid";

import SuiLink from "components/SuiLink";
import Icon from "components/Icon";

import SectionComponent from "view/layouts/SectionComponent";
import Loading from "view/layouts/Loading";
import Error from "view/layouts/Error";
import ItemProperty from "view/layouts/ItemProperty/ItemProperty";
import { objectOf, PropTypes } from "prop-types";

const SectionProperties = React.memo(({ properties, isLoading, isError }) => (
  <SectionComponent
    title="Les propriétés"
    subtitle="Les Propriétés récentes"
    textAlign="center"
    description="Liste de quelques propriétés ajoutées sur le site"
  >
    {isLoading ? (
      <Loading />
    ) : isError ? (
      <Error />
    ) : (
      <div
        className="uk-child-width-1-3@m uk-child-width-1-1 card-groupe uk-flex uk-flex-center uk-flex-between@s"
        uk-grid="true"
        uk-scrollspy="cls: uk-animation-scale-up; target: a; delay: 100; repeat: false"
      >
        {properties &&
          properties.map((property) => (
            <ItemProperty
              key={uuid()}
              id={property.id}
              price={property.price}
              city={property.city.name}
              region={property.city.region.name}
              typeSale={property.typeSale.name}
              defaultImage={property.defaultImage && property.defaultImage}
              type={property.type.name}
              createAt={property.createAt}
              nameBuild={property.nameBuild}
              address={property.address}
            />
          ))}
      </div>
    )}
    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
      <SuiLink
        size="medium"
        color="secondary"
        variant="contained"
        color2="primary"
        to="/properties"
      >
        voir plus <Icon type="l" icon="chevron-right" />
      </SuiLink>
    </Box>
  </SectionComponent>
));

SectionProperties.defaultProps = {};

SectionProperties.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  properties: PropTypes.arrayOf(objectOf).isRequired,
};

export default SectionProperties;
