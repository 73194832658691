import React from "react";
import { useQuery } from "react-query";
import { loadAxios } from "components/hooks/hookAxios";
import { useFilterProperty, setOptionFilterProperty } from "services/context/filter";

import FilterItems from "./FilterItems";
import CheckBox from "./CheckBox";

function FilterCity() {
  const [filterProperty, dispatch] = useFilterProperty();
  const { optionFilter } = filterProperty;

  const { data } = useQuery(["option"], () => loadAxios("options"));
  const options = data?.["hydra:member"] || [];

  const handleChange = (e) => {
    if (e.target.checked) {
      setOptionFilterProperty(dispatch, {
        name: [...optionFilter.name, e.target.dataset.name],
        data: [...optionFilter.data, e.target.value],
      });
    } else {
      setOptionFilterProperty(dispatch, {
        name: optionFilter.name.filter((c) => c !== e.target.dataset.name),
        data: optionFilter.data.filter((c) => c !== e.target.value),
      });
    }
  };

  return (
    <FilterItems content={optionFilter.name} id="option" label="caractérisque de la propriété">
      <div className="inputCheckbox">
        {options &&
          options.map((item) => (
            <div key={item.id} className="my-3">
              <CheckBox
                data={optionFilter.data}
                name={item.name}
                id={item.id}
                handleChange={handleChange}
              />
              <label className="" htmlFor={item.id}>
                {item.name}
              </label>
            </div>
          ))}
      </div>
    </FilterItems>
  );
}

export default React.memo(FilterCity);
