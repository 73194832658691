import { Box, alpha } from "@mui/material";
import React from "react";

function BoxDark() {
  return (
    <Box
      width={1}
      height={1}
      position="absolute"
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.black.main, 0.6),
        top: 0,
        zIndex: 4,
      }}
    />
  );
}

export default BoxDark;
