/* eslint-disable no-nested-ternary */
import React from "react";
import { useTheme } from "@mui/styles";
import { useQuery } from "react-query";
import { Box } from "@mui/material";

import { loadAxios } from "components/hooks/hookAxios";

import ItemAgent from "view/layouts/ItemAgent";
import SectionComponent from "view/layouts/SectionComponent";
import Loading from "view/layouts/Loading";
import Error from "view/layouts/Error";

function SectionAgent() {
  const theme = useTheme();
  const { data, isLoading, isError } = useQuery(["agents"], () => loadAxios("users"));
  const users = data?.["hydra:member"] || [];
  return (
    <SectionComponent
      subtitle="Liste des agents de FIYPRO"
      title="Les agents"
      bgcolor={theme.palette.grey[100]}
    >
      <div
        className="uk-child-width-1-4@m uk-child-1-2@s uk-child-1-1 uk-flex uk-flex-center card-groupe"
        uk-grid="true"
        uk-scrollspy="cls: uk-animation-scale-up; target: .item-agent; delay: 100; repeat: false"
      >
        {isLoading ? (
          <Box width={1}>
            <Loading />
          </Box>
        ) : isError ? (
          <Error />
        ) : (
          users &&
          users.map(
            (user) =>
              user.apiKey === "agent" && (
                <ItemAgent
                  id={user.id}
                  name={`${user.name} ${user.surname}`}
                  tel={user.phoneNumber}
                  email={user.address}
                />
              )
          )
        )}
      </div>
    </SectionComponent>
  );
}

export default SectionAgent;
