import { Box } from "@mui/material";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Icon from "components/Icon";

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes loaderAnimation": {
      from: {
        transform: "scale(0.5, 0.5)",
        opacity: "0.9",
      },
      to: {
        transform: "scale(1.7, 1.7)",
        opacity: "0",
      },
    },
    loader: {
      "& i": {
        fontSize: "60px",
        animation: "$loaderAnimation 1.6s linear infinite",
      },
    },
  })
);

function Loading() {
  const classes = useStyles();
  return (
    <Box
      className={classes.loader}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 1,
        color: (theme) => theme.palette.primary.focus,
      }}
    >
      <Icon type="d" icon="home-lg" />
    </Box>
  );
}

export default Loading;
