/* eslint-disable no-restricted-globals */
import React from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

import { useFilterProperty, setPriceFilterProperty } from "services/context/filter";

function FilterPrice() {
  const [filterProperty, dispatch] = useFilterProperty();
  const { priceFilter } = filterProperty;

  const { pathname } = useLocation();

  const handleChangeMin = (e) => {
    const val = e.target.value;
    if (val === "" || !isNaN(parseInt(val, 10))) {
      // setMin(val)
      setPriceFilterProperty(dispatch, [val, priceFilter[1]]);
    }
  };
  const handleChangeMax = (e) => {
    const val = e.target.value;
    if (val === "" || !isNaN(parseInt(val, 10))) {
      // setMax(val)
      setPriceFilterProperty(dispatch, [priceFilter[0], val]);
    }
  };

  return (
    <div className="filter">
      <Typography
        sx={{
          textTransform: "uppercase",
          fontWeight: 100,
          fontSize: "13px",
          color: (theme) =>
            pathname === "/home" ? theme.palette.white.main : theme.palette.primary.main,
        }}
      >
        Prix de propriétés
      </Typography>
      <div className="mx-0 uk-child-width-1-2 uk-flex uk-flex-between" uk-grid="true">
        <input
          className="input-search"
          type="text"
          id="min"
          name="price[gte]"
          value={priceFilter[0]}
          onChange={handleChangeMin}
          data-name="min"
          placeholder="Prix minimun"
        />
        <input
          className={
            parseInt(priceFilter[0], 10) <= parseInt(priceFilter[1], 10) ||
            (priceFilter[0] === "" && priceFilter[1] === "")
              ? "input-search"
              : "input-search form-danger"
          }
          id="max"
          name="price[lte]"
          type="text"
          value={priceFilter[1]}
          onChange={handleChangeMax}
          data-name="max"
          placeholder="Prix maximum"
        />
      </div>
    </div>
  );
}

export default FilterPrice;
