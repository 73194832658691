import React from "react";
import { Box } from "@mui/material";
import { PropTypes } from "prop-types";

function Icon({ icon, type, ...rest }) {
  return <Box {...rest} component="i" className={`fa${type} fa-${icon}`} />;
}

Icon.defaultProps = {
  icon: "",
  type: "",
};

Icon.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
};

export default Icon;
