/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { v4 as uuid } from "uuid";
import { createStyles, makeStyles } from "@mui/styles";

// statics image fiypro components
import img from "assets/images/bg.jpg";
import noImage from "assets/images/no-img.jpg";

// components fiypro
import { loadAxios } from "components/hooks/hookAxios";
import Icon from "components/Icon";

// layouts FIYPRO components
import HeaderLayout from "view/layouts/HeaderLayout";
import Error from "view/layouts/Error";
import Loading from "view/layouts/Loading";

const useStyles = makeStyles(() =>
  createStyles({
    carac: {
      marginTop: "20px !important",
    },
  })
);

function Property() {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(img);
  const [user, setUser] = useState({
    username: "",
    phone: "",
  });

  const classes = useStyles();

  const { id } = useParams();

  const { data, isLoading, isError } = useQuery(["property"], () => loadAxios(`properties/${id}`));
  const property = data || [];

  useEffect(() => {
    if (property) {
      const nameBuild = property.nameBuild ? property.nameBuild : "Pas de nom";
      const address = property.address ? property.address : "Pas d'adresse";
      setTitle(`${nameBuild}, ${address}`);
      if (property.user) {
        setUser({
          username: `${property.user.name} ${property.user.surname}`,
          phone: property.user.phoneNumber,
        });
      }

      // TODO: mettre a jour le lien pour la production
      if (property.images && property.images.length !== 0) {
        setImage(`/fipro_api/public/images/properties-images/${property.defaultImage}`);
      } else {
        setImage(img);
      }
    }

    return () => {};
  }, [property]);

  const Description = useCallback(
    () => (
      <Box className="container">
        <Box className="row" mt={2} width={1}>
          <Box
            className="col-12 col-sm-6"
            justifyContent="center"
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Icon icon="user" mr={1} color="secondary.main" />
            <Typography variant="body2" color="white.main">
              {user.username}
            </Typography>
          </Box>
          <Box
            className="col-12 col-sm-6"
            justifyContent="center"
            mt={2}
            display="flex"
            alignItems="center"
          >
            <Icon icon="phone" mr={1} color="secondary.main" />
            <Link href={`tel:+237${user.phone}`}>
              <Typography variant="body2" color="white.main">
                (+237) {user.phone}
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    ),
    [user]
  );

  return (
    <Box>
      <HeaderLayout
        title={title}
        img={[{ img: image, alt: image }]}
        description={<Description />}
      />
      <Box className="container my-5">
        {isLoading ? (
          <Box height={400} width={1}>
            <Loading />
          </Box>
        ) : isError ? (
          <Error />
        ) : (
          property && (
            <div className="row">
              <div className="col-12 col-md-8">
                <Box width={1}>
                  <div className="uk-position-relative" uk-slideshow="animation: fade">
                    <ul className="image-thumb uk-cover-container uk-slideshow-items">
                      {property.images.length !== 0 ? (
                        property.images.map((image1) => (
                          <li className="" key={uuid()}>
                            <img
                              src={`/fipro_api/public/images/properties-images/${image1.name}`}
                              alt={image1.name}
                              uk-cover="true"
                            />
                          </li>
                        ))
                      ) : (
                        <li>
                          <img src={noImage} alt={noImage} uk-cover />
                        </li>
                      )}
                    </ul>

                    <div className="uk-position-bottom-center uk-position-small">
                      <ul className="uk-thumbnav">
                        {property.images.length !== 0 ? (
                          property.images.map((image1, index) => (
                            <li uk-slideshow-item={index} key={uuid()}>
                              <a className="uk-cover-container thumb" href="#">
                                <img
                                  src={`/fipro_api/public/images/properties-images/${image1.name}`}
                                  uk-cover
                                  alt={image1.name}
                                />
                              </a>
                            </li>
                          ))
                        ) : (
                          <li uk-slideshow-item={0}>
                            <a className="uk-cover-container thumb" href="#">
                              <img src={noImage} uk-cover alt={noImage} />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="mt-0 col-12 col-md-4">
                <Box>
                  <Typography variant="h1" color="primary.focus">
                    Description
                  </Typography>
                  <Typography my={3} variant="body2" color="text.main">
                    {property.description}
                  </Typography>
                </Box>
                {/* {Partie des carcteristiques} */}
                <Box>
                  <Typography mb={2} variant="h4" color="primary.focus">
                    Caractéristiques
                  </Typography>
                  <div className="uk-child-width-1-2" uk-grid="true">
                    <Box display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="pencil-ruler" type="l" />
                      <Typography variant="body2">
                        {property.surface} m<sup>2</sup>
                      </Typography>
                    </Box>
                    <Box display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="shower" type="l" />
                      <Typography variant="body2">{property.numberShower} Toilette(s)</Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="bed" type="l" />
                      <Typography variant="body2">{property.numberBedroom} Chambre(s)</Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="knife-kitchen" type="l" />
                      <Typography variant="body2">{property.numberKitchen} cuisine(s)</Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="home" type="l" />
                      <Typography variant="body2">{property.type.name}</Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="building" type="l" />
                      <Typography variant="body2"> Etage N° {property.floor}</Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="handshake" type="l" />
                      <Typography variant="body2">
                        {property.marketable ? "" : "non"} discutable
                      </Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="handshake" type="l" />
                      <Typography variant="body2">{property.typeSale.name}</Typography>
                    </Box>
                  </div>
                </Box>
                {/* {Partie de la localisation} */}
                <Box>
                  <Typography mt={2} variant="h4" color="primary.focus">
                    Locatisation
                  </Typography>
                  <div className="uk-child-width-1-2" uk-grid="true">
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="city" type="l" />
                      <Typography variant="body2">
                        ({property.city.region.name}) {property.city.name}
                      </Typography>
                    </Box>
                    <Box className={classes.carac} display="flex" alignsItems="center">
                      <Icon color="secondary.focus" mr={2} icon="map-marker-alt" type="l" />
                      <Typography variant="body2">
                        {property.address ? property.address : "Pas d'adresse"}
                      </Typography>
                    </Box>
                    <Box
                      className={[classes.carac, "uk-width-1-1"].join(" ")}
                      display="flex"
                      alignsItems="center"
                    >
                      <Icon color="secondary.focus" mr={2} icon="building" type="l" />
                      <Typography variant="body2">
                        Nom batiment : {property.nameBuild ? property.nameBuild : "Pas de nom"}
                      </Typography>
                    </Box>
                  </div>
                </Box>
                {/* {Parite des options} */}
                <Box>
                  <Typography mt={2} variant="h4" color="primary.focus">
                    Options
                  </Typography>
                  <div className="uk-child-width-1-2" uk-grid="true">
                    {property.options.lenght !== 0 ? (
                      property.options.map((option) => (
                        <Box className={classes.carac} display="flex" alignsItems="center">
                          <Icon color="secondary.focus" mr={2} icon="circle" type="l" />
                          <Typography variant="body2">{option.name}</Typography>
                        </Box>
                      ))
                    ) : (
                      // eslint-disable-next-line react/no-unescaped-entities
                      <Typography varaint="body2">Pas d'options pour cette propiété</Typography>
                    )}
                  </div>
                </Box>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h3" color="primary.focus">
                    Prix :{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        md: (theme) => theme.typography.body1,
                        xs: "25px",
                      },
                    }}
                    color="secondary.focus"
                    variant="body1"
                    fontWeight={700}
                    fontSize={30}
                  >
                    {new Intl.NumberFormat("cm-CM", { style: "currency", currency: "XFA" }).format(
                      property.price
                    )}
                    {property.typeSale.name === "A louer" && "/Mois"}
                  </Typography>
                </Box>
              </div>
            </div>
          )
        )}
      </Box>
    </Box>
  );
}

export default Property;
