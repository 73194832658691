import React from "react";
import { Box, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";
import { PropTypes } from "prop-types";
import { createStyles, makeStyles } from "@mui/styles";

import decor from "assets/images/decor.png";

import BoxDark from "view/layouts/BoxDark/BoxDark";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      height: "100%",
      "& ul": {
        height: "100% !important",
        minHeight: "0px !important",
      },
    },
  })
);

function HeaderLayout({ img, title, description }) {
  const classes = useStyles();
  return (
    <Box sx={{ height: { md: "400px", xs: "300px" }, position: "relative" }}>
      <div
        className={[classes.header, "uk-position-relative"].join(" ")}
        tabIndex="-1"
        uk-slideshow="animation: fade; autoplay: true"
      >
        <ul className="uk-slideshow-items">
          {img.map((image) => (
            <Box component="li" key={uuid()}>
              <Box
                component="div"
                className="uk-position-cover  uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-left"
              >
                <img alt={image.alt} src={image.img} uk-cover="true" />
              </Box>
            </Box>
          ))}
        </ul>
      </div>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          zIndex: 9,
          p: 1,
        }}
      >
        <Typography
          sx={{
            mt: { md: "160px", xs: "90px" },
            fontSize: { md: 60, xs: 35 },
          }}
          textAlign="center"
          color="white.main"
          variant="h1"
          fontWeight={500}
        >
          {title}
        </Typography>
        {description}
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "20%",
          diplay: "flex",
          alignItems: "center",
          zIndex: 5,
        }}
        src={decor}
        alt={decor}
        component="img"
      />
      <BoxDark />
    </Box>
  );
}

HeaderLayout.defaultProps = {
  description: "",
};

HeaderLayout.propTypes = {
  img: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node,
};

export default React.memo(HeaderLayout);
