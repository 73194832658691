import React from "react";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { loadAxios } from "components/hooks/hookAxios";

import Header from "./components/Header";
import SectionProperties from "./components/SectionProperties";
import SectionAboutUs from "./components/SectionAboutUs";
import SectionSubmit from "./components/SectionSubmit";
import SectionAgent from "./components/SectionAgent";
import SectionPartners from "./components/SectionPartners";

function Home() {
  const { data, isLoading, isError } = useQuery(["properties-home"], () =>
    loadAxios("properties?order[createAt]&sold=false")
  );
  const properties = data?.["hydra:member"] || [];
  return (
    <Box>
      <Header />
      <SectionProperties properties={properties} isLoading={isLoading} isError={isError} />
      <SectionAboutUs />
      <SectionPartners />
      <SectionSubmit />
      <SectionAgent />
    </Box>
  );
}

export default Home;
