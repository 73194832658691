/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";

import Icon from "components/Icon";
import SectionComponent from "view/layouts/SectionComponent";
import { useTheme } from "@mui/styles";

function SectionAboutUs() {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <SectionComponent title="A propos de nous" subtitle="Notre vision" noContainer>
      <div className="services">
        <div className="content">
          <div className="container">
            <div className="section-content uk-flex uk-flex-right@m">
              <div className="feature">
                <div
                  className="uk-width-1-1 my-5"
                  uk-scrollspy="cls: uk-animation-slide-right; target: .div"
                >
                  <div className="div">
                    <Typography variant={media ? "h4" : "h2"}>Notre Mission</Typography>
                    <Typography variant="body2" textAlign="justify">
                      Notre mission est de fournir aux consommateurs l'information dont ils ont
                      besoin pour prendre des décisions informées au sujet des propriétés possibles.
                      <b>FIYPRO</b>est un marché immobilier voué à aider les propriétaires, les
                      acheteurs, les vendeurs, les locataires et les agents à trouver et à
                      communiquer des renseignements sur les propriétés.
                    </Typography>
                  </div>
                  <div
                    className="uk-child-width-1-2@m mt-5"
                    uk-grid="true"
                    uk-scrollspy="cls: uk-animation-slide-right; target: .advance; delay: 300; repeat: false"
                  >
                    <div className="advance">
                      <Box className="icon-advance">
                        <Icon color="secondary.focus" icon="coins" type="l" />
                      </Box>
                      <div className="advance-title">Économiser de l'argent</div>
                      <Typography variant="body2" textAlign="justify">
                        Nous implémentons une base de données immobilière qui vous permet de choisir
                        un bien en fonction de vos moyens.
                      </Typography>
                    </div>
                    <div className="advance">
                      <Box className="icon-advance">
                        <Icon color="secondary.focus" icon="chart-line" type="l" />
                      </Box>
                      <div className="advance-title">Bonnes ventes et marketing</div>
                      <Typography variant="body2" textAlign="justify">
                        Nous sommes en contact avec nos différents prestataires immobiliers et avec
                        vous, la municipalité du site pour faire en sorte que le service s'améliore.
                      </Typography>
                    </div>
                    <div className="advance">
                      <Box className="icon-advance">
                        <Icon color="secondary.focus" icon="sun" type="l" />
                      </Box>
                      <div className="advance-title">confort</div>
                      <Typography variant="body2" textAlign="justify">
                        Nous transformons la façon dont les consommateurs prennent des décisions
                        concernant leur domicile.{" "}
                      </Typography>
                    </div>
                    <div className="advance">
                      <Box className="icon-advance">
                        <Icon color="secondary.focus" icon="handshake" type="l" />
                      </Box>
                      <div className="advance-title">Service d'achat</div>
                      <Typography variant="body2" textAlign="justify">
                        Nous mettons aussi un service achat / vente sur la plateforme afin de
                        faciliter l'ameublement de votre propriété afin d'être votre image.Les
                        produits sont évidemment proposés par des fournisseurs sûrs sélectionnés par
                        nos soins.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionComponent>
  );
}

export default SectionAboutUs;
