import React from "react";
import { useFilterProperty, setTypeFilterProperty } from "services/context/filter";
import { useQuery } from "react-query";
import { loadAxios } from "components/hooks/hookAxios";

import FilterItems from "./FilterItems";
import CheckBox from "./CheckBox";

function FilterType() {
  // On recupere la valeur dans le context
  const [filterProperty, dispatch] = useFilterProperty();
  const { typeFilter } = filterProperty;

  const { data } = useQuery(["types"], () => loadAxios("types"));
  const types = data?.["hydra:member"] || [];
  const handleChange = (e) => {
    if (e.target.checked) {
      setTypeFilterProperty(dispatch, {
        name: [...typeFilter.name, e.target.dataset.name],
        data: [...typeFilter.data, e.target.value],
      });
    } else {
      setTypeFilterProperty(dispatch, {
        name: typeFilter.name.filter((c) => c !== e.target.dataset.name),
        data: typeFilter.data.filter((c) => c !== e.target.value),
      });
    }
  };
  return (
    <FilterItems content={typeFilter.name} id="type" label="type de propriété">
      <div className="inputCheckbox">
        {types &&
          types.map((item) => (
            <div key={item.id} className="my-3">
              <CheckBox
                data={typeFilter.data}
                name={item.name}
                id={item.id}
                handleChange={handleChange}
              />
              <label className="" htmlFor={item.id}>
                {item.name}
              </label>
            </div>
          ))}
      </div>
    </FilterItems>
  );
}

export default React.memo(FilterType);
