import React, { useState } from "react";
import { PropTypes } from "prop-types";

function CheckBox({ data, id, name, handleChange }) {
  const [checked, setChecked] = useState(data.includes(id));

  const handleChangeC = (e) => {
    setChecked(!checked);
    handleChange(e);
  };

  React.useEffect(() => {
    if (checked !== data.includes(`${id}`)) {
      setChecked(data.includes(`${id}`));
    }
  }, [data]);

  return (
    <input
      className="uk-checkbox me-2"
      onChange={handleChangeC}
      type="checkbox"
      data-name={name}
      value={id}
      checked={checked}
    />
  );
}

CheckBox.defaultProps = {
  data: [],
  name: "",
};

CheckBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default CheckBox;
