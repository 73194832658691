/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useInfiniteQuery } from "react-query";
import { v4 as uuid } from "uuid";
import { PropTypes } from "prop-types";

import image from "assets/images/img (5).jpg";

import { useFilterProperty } from "services/context/filter";
import { paginatedAxios } from "components/hooks/hookAxios";

import HeaderLayout from "view/layouts/HeaderLayout";
import Loading from "view/layouts/Loading";
import ItemProperty from "view/layouts/ItemProperty/ItemProperty";
import Error from "view/layouts/Error";
import SuiButton from "components/SuiButton";
import Search from "view/layouts/Search";
import NoFound from "view/layouts/NoFound";
import Icon from "components/Icon";
import { Outlet } from "react-router-dom";

const Description = React.memo(({ total }) => (
  <Box display="flex" justifyContent="center">
    <Box>
      <Typography variant="body2" color="white.main" fontWeight={100}>
        Nombre total de propriétés : {total}
      </Typography>
    </Box>
  </Box>
));

Description.propTypes = {
  total: PropTypes.number.isRequired,
};

function Properties() {
  // Context pour la gestion des filtres
  const [filterProperty] = useFilterProperty();

  // Vérifie s'il y a encore de éléménts à charger pour le chargement infinie des propriétés
  const [next, setNext] = useState(true);

  // State pour Nombre total de propriétés trouver
  const [total, setTotal] = useState(0);

  // Paramètre des filtres
  const { urlParams, cityFilter, priceFilter, typeFilter, optionFilter } = filterProperty;

  const { data, fetchNextPage, isFetching, isLoading, hasNextPage, isError, refetch } =
    useInfiniteQuery(
      ["properties"],
      ({ pageParam }) =>
        paginatedAxios(
          "properties",
          `&order[createAt]&sold=false${urlParams}`,
          setTotal,
          setNext,
          pageParam
        ),
      {
        getNextPageParam: (lastPage, allPage) => (next ? allPage.length + 1 : undefined),
      }
    );
  const pties = data?.pages?.flat() || [];

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    refetch();
  }, [urlParams]);

  const nextFetch = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const background = useMemo(() => [{ img: image, alt: image }], [image]);

  // eslint-disable-next-line no-shadow
  const Description1 = useCallback(
    () => (
      <Box display="flex" justifyContent="center">
        <Box>
          <Typography textAlign="center" variant="body2" color="white.main" fontWeight={100}>
            Nombre total de propriétés : {total}
          </Typography>
          {urlParams !== "" && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                sx={{ display: { xs: "none", sm: "block" } }}
                variant="body2"
                color="white.main"
                fontWeight={100}
              >
                Filtre Acitif :
              </Typography>
              {cityFilter.name.length !== 0 && (
                <Box ml={1} display="flex" alignItems="center">
                  <Icon mr={1} icon="map-marker-alt" color="secondary.main" />
                  <Typography color="white.main" variant="caption">
                    : {cityFilter.name.join(", ")}
                  </Typography>
                </Box>
              )}
              {typeFilter.name.length !== 0 && (
                <Box ml={1} display="flex" alignItems="center">
                  <Icon mr={1} icon="home" color="secondary.main" />
                  <Typography color="white.main" variant="caption">
                    : {typeFilter.name.join(", ")}
                  </Typography>
                </Box>
              )}
              {optionFilter.name.length !== 0 && (
                <Box ml={1} display="flex" alignItems="center">
                  <Icon mr={1} icon="filter" color="secondary.main" />
                  <Typography color="white.main" variant="caption">
                    : {optionFilter.name.join(", ")}
                  </Typography>
                </Box>
              )}
              {priceFilter[0] !== "" && priceFilter[1] !== "" && (
                <Box ml={1} display="flex" alignItems="center">
                  <Icon mr={1} icon="money-bill" color="secondary.main" />
                  <Typography color="white.main" variant="caption">
                    : [ {priceFilter.join(", ")} ]XFA
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    ),
    [total, urlParams]
  );

  return (
    <Box mb={4}>
      <HeaderLayout img={background} title="Propriétés" description={<Description1 />} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 border-danger border-1 pb-5">
            {isLoading ? (
              <Box height={400} width={1}>
                <Loading />
              </Box>
            ) : isError ? (
              <Error />
            ) : (
              <>
                <div
                  className="uk-child-width-1-3@m uk-child-width-1-1 card-groupe uk-flex uk-flex-center uk-flex-between@m"
                  uk-grid="true"
                >
                  {pties.length !== 0 ? (
                    pties.map((property) => (
                      <ItemProperty
                        key={uuid()}
                        id={property.id}
                        price={property.price}
                        city={property.city.name}
                        region={property.city.region.name}
                        typeSale={property.typeSale.name}
                        defaultImage={property.defaultImage && property.defaultImage}
                        type={property.type.name}
                        createAt={property.createAt}
                        nameBuild={property.nameBuild}
                        address={property.address}
                      />
                    ))
                  ) : (
                    <NoFound />
                  )}
                </div>
                <Outlet />
              </>
            )}
            {pties.length !== 0 && (
              <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <SuiButton
                  color="secondary"
                  onClick={nextFetch}
                  disabled={isFetching || !hasNextPage}
                >
                  {isFetching
                    ? "Chargement..."
                    : hasNextPage
                    ? "Charger plus"
                    : "Plus rien à charger"}
                </SuiButton>
              </Box>
            )}
          </div>
          <div className="col-md-3 col-12">
            <div className="a-filter">
              <aside className="aside-filter uk-position-z-index">
                <Typography mb={1} variant="h3">
                  Recherche
                </Typography>
                <div className="widget-box uk-box-shadow-small@s">
                  <Search />
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default React.memo(Properties);
