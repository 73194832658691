import React, { useMemo } from "react";
import { Box } from "@mui/material";
import * as yup from "yup";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import image from "assets/images/Contact.png";

import Fieldset from "components/SuiForm/components/Fieldset";
import SuiButton from "components/SuiButton";
import Icon from "components/Icon";

import HeaderLayout from "view/layouts/HeaderLayout";

function Description() {
  return (
    <Box width={1} className="uk-container">
      <Box />
    </Box>
  );
}

function ContactUs() {
  const background = useMemo(() => [{ img: image, alt: image }], [image]);
  const schemaContact = yup.object({
    fullName: yup.string().required("Veillez entrer votre nom"),
    email: yup
      .string()
      .email("Veillez entrer une adresse mail valide")
      .required("Veillez entrer votre email"),
    phone: yup.string().required("Veillez entrer votre numéro de téléphone"),
    subject: yup.string().required("Veillez entrer un sujet"),
    message: yup.string().required("Veillez un message"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaContact),
  });

  const sendMail = (data) => {
    const params = {
      from_name: data.fullName,
      to_name: "Site de FIYPRO",
      subject: data.subject,
      phone: data.phone,
      email: data.email,
      message: data.message,
    };

    toast.promise(
      emailjs.send("service_pc1ngt2", "template_kf0c13o", params, "q5FPCuEKjj_YBp-rR").then(
        () => "L'envoi de votre formulaire a été effectué avec succès.",
        (error) => {
          throw new Error(error);
        }
      ),
      {
        pending: "Envoie du message en cours",
        success: {
          render({ data: dataPromise }) {
            return dataPromise;
          },
        },
        error: "Une erreur est survenue lors de l'envoi",
      }
    );
  };

  const formField = [
    {
      name: "fullName",
      label: "Nom complet",
      type: "text",
      classes: "col-sm-6 col-12",
    },
    {
      name: "email",
      label: "Adresse mail",
      type: "email",
      classes: "col-sm-6 col-12",
    },
    {
      name: "phone",
      label: "Numéro de téléphone",
      type: "tel",
      classes: "col-12",
    },
    {
      name: "subject",
      label: "Sujet",
      type: "text",
      classes: "col-12",
    },
    {
      name: "message",
      label: "Message",
      type: "textArea",
      classes: "col-12",
    },
  ];

  return (
    <Box mb={4}>
      <HeaderLayout img={background} title="Contactez-nous" description={<Description />} />
      <Box className="container">
        <Box my={4} component="form" onSubmit={handleSubmit(sendMail)}>
          <Box className="row">
            {formField.map((field) => (
              <Fieldset
                name={field.name}
                control={control}
                errors={errors}
                type={field.type}
                classes={field.classes}
                label={field.label}
              />
            ))}
          </Box>
          <SuiButton sx={{ mt: 4 }} color="secondary" type="submit">
            Envoyer <Icon icon="paper-plane" color="primary.focus" ml={1} />
          </SuiButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUs;
