/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
import React, { memo } from "react";
import { Box, Link, useMediaQuery, alpha, Typography } from "@mui/material";
import { createStyles, useTheme, makeStyles } from "@mui/styles";

import SuiButton from "components/SuiButton/index";
import Icon from "components/Icon";
import SuiLink from "components/SuiLink";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";

const useStyles = makeStyles((theme) =>
  createStyles({
    white: {
      color: `${theme.palette.white.main} !important`,
      "&:hover": {
        color: theme.palette.white.main,
      },
    },

    primary: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);

const TopBar = memo(() => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const isAuth = useIsAuthenticated();
  const user = useAuthUser();
  const logOut = useSignOut();
  return (
    <Box
      sx={{
        height: { xs: 35, md: 50 },
        borderBottom: (theme) => `1px solid ${alpha(theme.palette.grey["100"], 0.3)}`,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box className="container">
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box display="flex">
            {matches && (
              <SuiLink fontWeight="400" color2="white" to="/" size="large" variant="text">
                <Icon color="secondary.focus" mr={1} icon="map-marker-alt" />
                Bangangté
              </SuiLink>
            )}
            <SuiButton
              sx={{ fontWeight: "400 !important" }}
              size="large"
              variant="text"
              color="white"
            >
              <Icon color="secondary.focus" mr={1} icon="phone" />
              <Link className={classes.white} href="tel:+237691519347">
                (+237) 691 519 347
              </Link>
            </SuiButton>
            {matches && (
              <SuiButton
                sx={{ fontWeight: "400 !important" }}
                size="large"
                variant="text"
                color="white"
              >
                <Icon color="secondary.focus" mr={1} icon="envelope" />
                <Link className={classes.white} href="mailto:info.fiypro.com">
                  info.fiypro@gmail.com
                </Link>
              </SuiButton>
            )}
          </Box>
          <Box>
            {isAuth() ? (
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{ display: { sm: "flex", xs: "none" } }}
                  fontwWeight={200}
                  variant="body2"
                  color="white.main"
                >
                  {user().username} /
                </Typography>
                <SuiButton variant="text" color="white" onClick={() => logOut()} size="large">
                  Se déconnecter
                </SuiButton>
              </Box>
            ) : (
              <SuiLink fontWeight="400" color2="white" to="/login" size="large" variant="text">
                S'identifier
              </SuiLink>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default TopBar;
