/* eslint-disable no-nested-ternary */
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import image from "assets/images/bg (Grande).jpg";

import PropertyModel from "components/PropertyModel";
import { loadAxios } from "components/hooks/hookAxios";

import HeaderLayout from "view/layouts/HeaderLayout";
import Loading from "view/layouts/Loading";
import Error from "view/layouts/Error";

function index() {
  const [defaultValues, setDefaultValues] = useState({});
  const img = useMemo(() => [{ img: image, alt: image }], [image]);
  const { id } = useParams();

  const { data, isLoading, isError } = useQuery(["property"], () => loadAxios(`properties/${id}`));
  const property = data || [];

  useEffect(() => {
    if (property && property.id) {
      const row = [];
      property.options.forEach((option) => {
        row.push({
          value: option["@id"],
          label: option.name,
        });
      });

      setDefaultValues({
        city: property?.city["@id"],
        type: property?.type["@id"],
        typeSale: property?.typeSale["@id"],
        floor: property?.floor,
        address: property?.address,
        nameBuild: property?.nameBuild,
        price: property?.price,
        marketable: property?.marketable,
        numberBedroom: property?.numberBedroom,
        numberKitchen: property?.numberKitchen,
        numberShower: property?.numberShower,
        surface: property?.surface,
        description: property?.description,
        defaultImage: property?.defaultImage,
        options: row,
      });
    }
  }, [property]);

  const method = {
    method: "patch",
    id,
  };

  return (
    <Box>
      <HeaderLayout title="édititer la propriété" img={img} />
      {isLoading ? (
        <Box height={600} display="flex" alignItems="center">
          <Loading />
        </Box>
      ) : isError ? (
        <Error />
      ) : (
        property &&
        property.nameBuild === defaultValues.nameBuild && (
          <PropertyModel defaultValues={defaultValues} method={method} />
        )
      )}
    </Box>
  );
}

export default index;
