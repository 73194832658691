import { Box } from "@mui/material";
import React from "react";
import SectionComponent from "view/layouts/SectionComponent";

function SectionPartners() {
  return (
    <SectionComponent title="Nos partenaires" subtitle="Tous nos partenaires" textAlign="center">
      <div
        className="uk-position-relative uk-visible-toggle uk-light"
        tabIndex="-1"
        uk-slider="autoplay: true"
      >
        <Box
          component="ul"
          className="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-grid"
        >
          <Box component="li" className="uk-panel" />
        </Box>
      </div>
    </SectionComponent>
  );
}

export default SectionPartners;
