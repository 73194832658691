/* eslint-disable no-underscore-dangle */
import { postAxios } from "components/hooks/hookAxios";

async function refreshToken(object) {
  const refreshT = { refresh_token: object.cookie._auth_refresh };
  const response = await postAxios("/token/refresh", refreshT);
  if (response.isSuccess) {
    object.setCookie("_auth", response?.data.token, { path: "/" });
  }
}

export default refreshToken;
