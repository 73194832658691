/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import React from "react";
import { PropTypes } from "prop-types";
import { Controller } from "react-hook-form";
import Select from "react-select";

// import { v4 as uuid } from "uuid";

// components fiypro.
import SuiInput from "components/SuiInput";
import LabelInput from "components/SuiInput/LabelInput";
import SuiSelect from "components/SuiSelect";
// import SelectItem from "components/SuiSelect/SelectItems";
import { MenuItem, Box, Typography, Checkbox } from "@mui/material";
import { menuItem } from "components/SuiSelect/styles";

function Fieldset({
  name,
  label,
  type,
  required,
  control,
  errors,
  classes,
  message,
  callback,
  options = null,
}) {
  return (
    <div className={classes}>
      <LabelInput>
        {label} {required && <sup>*</sup>}
      </LabelInput>
      <Controller
        name={name}
        control={control}
        rule={{ required }}
        render={({ field }) => {
          if (
            type === "text" ||
            type === "number" ||
            type === "date" ||
            type === "password" ||
            type === "tel" ||
            type === "email"
          ) {
            return (
              <Box>
                <SuiInput type={type} error={errors[name] && true} {...field} />
                <Typography variant="caption" color="error.main">
                  {errors[name]?.message}
                </Typography>
                <Typography display="block" lineHeight={1.2} variant="caption" color="text.main">
                  {message}
                </Typography>
              </Box>
            );
          }
          if (type === "textArea") {
            return (
              <Box>
                <SuiInput row={3} multiline type={type} error={errors[name] && true} {...field} />
                <Typography variant="caption" color="error.main">
                  {errors[name]?.message}
                </Typography>
                <Typography display="block" lineHeight={1.2} variant="caption" color="text.main">
                  {message}
                </Typography>
              </Box>
            );
          }
          if (type === "select") {
            return (
              <Box>
                <SuiSelect {...field}>
                  {options &&
                    options.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option["@id"]}
                        sx={(theme) => menuItem(theme)}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                </SuiSelect>
                <Typography display="block" lineHeight={1.2} variant="caption" color="text.main">
                  {message}
                </Typography>
              </Box>
            );
          }
          if (type === "selectMultiple") {
            // eslint-disable-next-line no-shadow
            const { name, ref } = field;
            const row = [];
            options &&
              options.forEach((option) => {
                row.push({
                  value: option["@id"],
                  label: option.name,
                });
              });
            return (
              <Select
                classNamePrefix="select"
                isMulti
                className="basic-multi-select"
                name={name}
                onChange={callback.handleChangeOptions}
                value={callback.optionsSelect}
                ref={ref}
                options={row}
              />
            );
          }
          if (type === "switch") {
            return <Checkbox {...field} />;
          }
        }}
      />
    </div>
  );
}

Fieldset.defaultProps = {
  name: "",
  label: "",
  required: false,
  options: [],
  classes: "",
  message: "",
  callback: null,
};

Fieldset.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  control: PropTypes.node.isRequired,
  options: PropTypes.node,
  errors: PropTypes.node.isRequired,
  classes: PropTypes.string,
  message: PropTypes.string,
  callback: PropTypes.node,
};

export default Fieldset;
