// import { forwardRef } from "react";

import PropTypes from "prop-types";

import SuiSelectRoot from "components/SuiSelect/SuiSelectRoot";

// eslint-disable-next-line arrow-body-style
function SuiSelect({ size, error, success, disabled, ...rest }) {
  return <SuiSelectRoot {...rest} ownerState={{ size, error, success, disabled }} />;
}

SuiSelect.defaultProps = {
  size: "medium",
  success: false,
  error: false,
  disabled: false,
};

SuiSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SuiSelect;
