/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
import { Box, Card, CardMedia, Typography } from "@mui/material";
import React from "react";
import slugify from "slugify";
import { useAuthHeader } from "react-auth-kit";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";

import noImage from "assets/images/no-img.jpg";

import { postAxios, bearerAxios } from "components/hooks/hookAxios";
import Icon from "components/Icon";
import SuiLink from "components/SuiLink";
import SuiButton from "components/SuiButton";

// eslint-disable-next-line react/prop-types
function ItemPropertyProfil({ image, nameBuild, price, address, sold, id }) {
  const name = nameBuild && nameBuild !== "" ? nameBuild : "pas de nom";
  const slug = slugify(name);

  const token = useAuthHeader();
  const finalToken = token();

  const queryClient = useQueryClient();
  const { mutate: deleteProperty } = useMutation(
    async (id) => {
      const response = await toast.promise(bearerAxios(`properties/${id}`, finalToken, "DELETE"), {
        pending: "Suppression en cours... ",
        success: "La propriété a été bien supprimer",
        error: "Une erreur est survenue lors de la suppression",
      });
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          queryClient.setQueryData(["profilProperties"], (ptiesClient) => {
            const ptiesData = ptiesClient.data["hydra:member"].filter((p) => p.id !== id);
            return {
              data: {
                "hydra:member": ptiesData,
              },
              isSuccess: ptiesClient.isSuccess,
            };
          });
        }
      },
    }
  );
  // eslint-disable-next-line react/prop-types
  function Msg({ closeToast, id }) {
    return (
      <Box>
        <Typography fontWeight="bold" textAlign="center" variant="body2">
          Confirmer la suppression
        </Typography>
        <Box display="flex" justifyContent="space-around">
          <SuiButton color="error" size="small" onClick={() => deleteProperty(id)}>
            supprimer
          </SuiButton>
          <SuiButton ml={1} color="secondary" size="small" onClick={closeToast}>
            annuler
          </SuiButton>
        </Box>
      </Box>
    );
  }

  const handleDelete = (id) => {
    toast.warn(<Msg id={id} />, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: false,
    });
  };

  // Pour changer le statut d'occupation d'une propriete
  const { mutate: changeStatut } = useMutation(
    async (id) => {
      const response = await toast.promise(postAxios(`properties/${id}/sellOff`, {}), {
        pending: "Changement de Statut en cour",
        success: "Le changement a été éffectué",
        error: "Une erreur est suvenue",
      });
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.isSuccess) {
          queryClient.setQueryData(["profilProperties"], (propertiesClient) => {
            const propertiesData = propertiesClient.data["hydra:member"].map((property) =>
              property.id === data.data.id ? { ...property, sold: data.data.sold } : property
            );
            return {
              data: {
                "hydra:member": propertiesData,
              },
              isSuccess: propertiesClient.isSuccess,
            };
          });
        }
      },
    }
  );
  return (
    <Card
      className="col-12 my-3 mx-2 col-md-6 col-sm-6 uk-box-shadow-large"
      sx={{
        display: "flex",
        height: { sm: 235, xs: 290 },
        width: { md: 550, sm: "100%", xs: 350 },
        p: 0,
      }}
    >
      <CardMedia
        image={image ? `/fipro_api/public/images/properties-images/${image}` : noImage}
        alt={image}
        component="img"
        sx={{ width: { sm: 280, xs: 140 } }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Typography variant="h5">{nameBuild || "pas de nom"}</Typography>
        <Typography variant="body2">
          Prix :{" "}
          {new Intl.NumberFormat("cm-CM", { style: "currency", currency: "XFA" }).format(price)}
        </Typography>
        <Typography variant="body2">Adresse : {address}</Typography>
        <Typography variant="body2">
          Statut :{" "}
          {!sold ? (
            <Box
              component="span"
              sx={{
                bgcolor: (theme) => theme.palette.grey[300],
                borderRadius: 5,
                px: 1,
              }}
            >
              Inoccupé
            </Box>
          ) : (
            <Box
              component="span"
              sx={{ bgcolor: (theme) => theme.palette.success.light, borderRadius: 5, px: 1 }}
            >
              Occupé
            </Box>
          )}
        </Typography>
        <Box>
          <Typography variant="h5">Actions</Typography>
          <Box display="flex" justifyContent="space-around">
            <Box>
              <SuiLink
                variant="text"
                size="large"
                color2="primary"
                to={`/properties/${slug}-${id}`}
              >
                <Icon icon="eye" color="primary.main" mr={1} />
                Détails
              </SuiLink>
            </Box>
            <Box>
              <SuiLink variant="text" size="large" color2="primary" to={`/properties/edit/${id}`}>
                <Icon icon="edit" color="primary.main" mr={1} />
                Editer
              </SuiLink>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-around">
            <SuiButton onClick={() => handleDelete(id)} variant="text" size="large" color="primary">
              <Icon icon="trash" color="primary.main" mr={1} />
              supp
            </SuiButton>
            <SuiButton onClick={() => changeStatut(id)} variant="text" size="large" color="primary">
              <Icon icon="random" color="primary.main" mr={1} />
              changer satut
            </SuiButton>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ItemPropertyProfil;
