import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { PropTypes } from "prop-types";

import Icon from "components/Icon/index";
import { useLocation } from "react-router-dom";

function FilterItems({ label, children, id, content }) {
  const [text, setText] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    if (content !== []) {
      setText(content.join(", "));
    } else {
      setText("Quelconque");
    }
  }, [content]);

  return (
    <Box className="filter">
      <Typography
        sx={{
          textTransform: "uppercase",
          fontWeight: 100,
          fontSize: "13px",
          color: (theme) =>
            pathname === "/home" ? theme.palette.white.main : theme.palette.primary.main,
        }}
      >
        {label}
      </Typography>
      <Box className="button-filter">
        <div
          className="content-selector uk-flex uk-flex-middle"
          uk-toggle={`target: #${id}; animation:uk-animation-slide-bottom-small`}
        >
          <Box className="filter-text">{text.length !== 0 ? text : "Quelconque"}</Box>
          <Box component="span" className="icone uk-flex uk-flex-center uk-flex-middle">
            <Icon type="l" icon="chevron-down" />
          </Box>
        </div>
        <div id={id} className="content-filter uk-panel uk-panel-scrollable" hidden>
          {children}
        </div>
      </Box>
    </Box>
  );
}

FilterItems.defaultProps = {
  label: "",
  id: "",
  content: [],
};

FilterItems.propTypes = {
  label: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FilterItems;
