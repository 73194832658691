import React from "react";
import { Box } from "@mui/material";

import Icon from "components/Icon";

function ToTop() {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <Box
      id="totop"
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={handleTop}
      sx={{
        position: "fixed",
        width: 70,
        height: 70,
        bottom: -100,
        right: 20,
        cursor: "pointer",
        boxShadow: (theme) => theme.shadows[5],
        zIndex: 99999,
        borderRadius: "50%",
        bgcolor: (theme) => theme.palette.secondary.main,
      }}
      className="show1"
    >
      <Icon icon="chevron-up" color="primary.focus" />
    </Box>
  );
}

export default ToTop;
