/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { Box } from "@mui/material";

import image from "assets/images/bg-feature.jpg";

import { loadAxios } from "components/hooks/hookAxios";

import HeaderLayout from "view/layouts/HeaderLayout";
import Loading from "view/layouts/Loading";
import Error from "view/layouts/Error";
import ItemAgent from "view/layouts/ItemAgent";

function Agents() {
  const { data, isLoading, isError } = useQuery(["agents"], () => loadAxios("users"));
  const users = data?.["hydra:member"] || [];

  const background = useMemo(() => [{ img: image, alt: image }], [image]);

  return (
    <Box mb={4}>
      <HeaderLayout img={background} title="Nos Agents" />
      <Box className="container">
        <div
          className="uk-child-width-1-4@m my-5 uk-child-1-2@s uk-child-1-1 uk-flex uk-flex-center card-groupe"
          uk-grid="true"
          uk-scrollspy="cls: uk-animation-scale-up; target: .item-agent; delay: 100; repeat: false"
        >
          {isLoading ? (
            <Box width={1}>
              <Loading />
            </Box>
          ) : isError ? (
            <Error />
          ) : (
            users &&
            users.map(
              (user) =>
                user.apiKey === "agent" && (
                  <ItemAgent
                    id={user.id}
                    name={`${user.name} ${user.surname}`}
                    tel={user.phoneNumber}
                    email={user.address}
                  />
                )
            )
          )}
        </div>
      </Box>
    </Box>
  );
}

export default Agents;
