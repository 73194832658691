import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/styles";
import { PropTypes } from "prop-types";

function SectionComponent({
  bgcolor,
  textAlign,
  title,
  subtitle,
  noContainer,
  description,
  children,
}) {
  const themes = useTheme();
  const matches = useMediaQuery(themes.breakpoints.down("md"));
  return (
    <Box
      component="section"
      sx={{
        bgcolor,
        mt: 5,
        py: 6,
      }}
    >
      <Box className={noContainer ? "" : "container"}>
        <Box className={noContainer ? "container" : ""}>
          <Box color="secondary.focus" textAlign={textAlign}>
            <Typography mb={2} fontWeight="bold" variant="body2">
              {title}
            </Typography>
          </Box>
          <Box textAlign={textAlign}>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.focus,
              }}
              fontSize={matches ? 40 : 50}
              variant={matches ? "h3" : "h1"}
              fontWeight="700"
            >
              {subtitle}
            </Typography>
          </Box>
          <Box textAlign={textAlign}>
            <Typography
              sx={{ color: (theme) => theme.palette.primary.focus }}
              variant="caption"
              fontWeight="400"
            >
              {description}
            </Typography>
          </Box>
        </Box>

        <Box mt={4}>{children}</Box>
      </Box>
    </Box>
  );
}

SectionComponent.defaultProps = {
  bgcolor: "white",
  textAlign: "left",
  subtitle: "",
  title: "",
  description: "",
  noContainer: false,
};

SectionComponent.propTypes = {
  bgcolor: PropTypes.string,
  textAlign: PropTypes.oneOf(["center", "left"]),
  subtitle: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  noContainer: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default SectionComponent;
